/* .selector { 
  outline: 1px solid green;
} */
.selector label {
  /* line-height: 36px; */
  height: inherit;
  display: flex;
  align-items: center;
}

.selector select,
.selector select:focus {
  /* height: 36px; */
      width: 150px;
      height: 32px;
      border: 1px solid #999;
      border-radius: unset;

  padding: 0 5px;
  padding-right: 30px;
  /* border: 1px #f1f1f1 solid; */
  height: inherit;
}

.selector select.size {
  width: 94px;
}
.selector.reverse select {
  width: 70px;
  padding-right: 0;
}
.selector.reverse label {
  padding-left: 5px;
}
.overvieew-pagination {
  /* flex: 0 0 50px; */
  display: flex;
  justify-content: center;

  padding: 10px 0;
  margin: 20px 20px 0;
  border-top: 1px #f1f1f1 solid;
}

.overvieew-pagination ul {
  display: flex;
  list-style: none;
}

.overvieew-pagination ul li button {
  color: #203866;
  width: 44px;
  height: 44px;
}

.overvieew-pagination ul li {
  border: 1px #e5e5e5 solid;
  color: #203866;
  margin: 5px;
  display: flex;
  justify-content: center;
}
.overvieew-pagination ul li:hover {
  border: 1px solid #004779;
  transition: all .25s ease-in-out;
}

.overvieew-pagination ul li.leftCurve {
  border-radius: 43px 0 0 43px;
  /* border: 0; */
}

.overvieew-pagination ul li.rightCurve {
  border-radius: 0 43px 43px 0;
  /* border: 0; */
}

.overvieew-pagination ul li.a {
  background: #00457c;
  color: #fff !important;
}

.overvieew-pagination ul li.a button {
  color: #fff !important;
}
.loaderWrapper {
    top: 0;
    left: 0;
    z-index: 1020;
    background: rgba(0, 0, 0, 0.3);
}

.loader {
    border: 4px solid #fff;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.6s linear infinite;
    position: absolute;
    z-index: 1000;
    /* box-shadow: 0 0 10px #000; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.overview {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    max-width: 1134px;
    margin: 0 auto;
}

.removeBgImg {
    background-image: none !important;
    background-color: #fff !important;
}

.overview__tabs {
    display: flex;
    align-items: flex-end;
}
.overview__tab {
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
    height: 39px;
    line-height: 40px;
    position: relative;
    margin: 0;
    border: 1px solid #000;
    background-color: #eee;
}
.overview__tab.active {
    border: 0;
    border: 1px solid #000;
    border-bottom: 0;
    background: #fff;
    height: 41px;
}
/* .overview__tab:not(:first-child) {
border-left: 0;
} */
.overview__tab.active:not(:first-child) {
    border-left: 1px solid #000;
}
.overview__tab.active:first-child {
    border-right: 1px solid #000;
}
.overview__tab:first-child {
    border-right: 0;
}
.overview-header {
    flex: 0 0 65px;
    align-items: flex-end;

    /* border: 1px double red; */
}

.overview-header {
    display: flex;
    justify-content: space-between;

    /* padding: 10px 0; */
    margin: 0 0 20px;
    /* border-bottom: 1px #f1f1f1 solid; */
    /* border-bottom: 1px #000 solid; */
    height: 50px;
}
.overview-header h3 {
    font-size: 16px;
    font-family: arial;
    font-weight: normal;
    text-transform: capitalize;
    /* outline: 1px red solid; */
    /* line-height: 2em; */
}

.overview__action {
    /* outline: 1px green solid; */
    border-bottom: 1px solid #000;
    display: flex;
    height: 39px;
    height: 45px;
    margin-top: 12px;
    flex: 1 1;
    justify-content: flex-end;
}
.overview__action section:first-of-type {
    padding: 0 20px 0 0;
}

.overview__action section:first-of-type label {
    padding: 0 15px 0 0;
}
.overview__action label {
    font-family: arial;
    font-size: 12px;
    padding: 0 15px 0 0;
    width: auto;
    align-items: center;
    justify-content: flex-end;
}
.overview__action section {
    display: flex;
    height: 32px;
    font-family: arial;
    font-size: 12px;
}
.overview__action section.reverse {
    flex-direction: row-reverse;
}

.overview-list {
    /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; */
    width: 100%;
    max-width: 1174px;
    margin: 0 auto;
}

.end_customer_quotes .list-wrap {
    background: #071639;
}
.end_customer_quotes .list-wrap > div {
    padding: 0;
}
.end_customer_quotes .list-image img {
    width: auto;
}
.grid-list {
    /* display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */

    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.list-wrap {
    /* box-sizing: border-box;
    margin-bottom: 20px;
    margin: 0 0px 20px;
    min-height: 185px;
    margin-right: 30px;
    width: 164px;
    outline: 1px solid #cccccc;
    display: flex;
    flex-direction: column; */

    box-sizing: border-box;
    margin: 0 15px 20px 15px;
    min-height: 185px;
    width: 164px;
    outline: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
}

.list-wrap:hover {
    cursor: pointer;
}

.list-wrap > div {
    position: relative;
    padding: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.list-image {
    width: 100%;
    height: 90px;

    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.list-image img {
    height: inherit;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
}
.end_customer_quotes .list-image img {
    padding: 10px;
}
.list-action {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    background: #fff;

    width: 33px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-action button {
    line-height: 1;
    width: inherit;
    height: inherit;
}

.list-wrap:hover .list-action {
    opacity: 1;
}

.list-action button + button {
    margin-left: 5px;
}
.list-action svg {
    fill: #333333;
}
.list-info {
    /* outline: 1px solid pink; */
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    color: #666666;
    padding-bottom: 10px;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: arial;
    font-size: 14px;
}
.list-info p {
    font-weight: bold;
    color: #000;
    margin-bottom: 0px;
    padding: 0 10px;
    height: 35px;
    line-height: 17px;
    font-size: 14px;
    /* word-break: break-all; */

    /* white-space: nowrap; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: block; */
}

.end_customer_quotes .list-info,
.end_customer_quotes .list-info p {
    color: #fff;
}
.overview .overview__action button {
    margin-left: 10px;
}
.end_customer_quotes .list-image {
    background-color: #fff;
}
.list-action button:hover,
.list-action button:active,
.list-action button:focus,
.overview button:hover,
.overview button:active,
.overview button:focus {
    background: none;
    border: 0;
    outline: none;
}

@media (max-width: 1315px) {
	.alist-wrap {
		/* flex-basis: 33.33%; */
		/* background: red; */
		width: calc(100% / 5);
	}
}

@media (max-width: 1073px) {
	.alist-wrap {
		/* flex-basis: 33.33%; */
		width: calc(100% / 4);
		/* background: pink; */
	}
}

@media (max-width: 800px) {
	.alist-wrap {
		/* flex-basis: 48%; */

		width: calc(100% / 3);
		/* background: purple; */
	}
}

@media (max-width: 555px) {
	.alist-wrap {
		/* flex-basis: 100%; */
		width: calc(100% / 2);
		/* background: blue; */
	}
}


.backgroundBoxWrap .errorBox {
  width: 400px;
  background: #fff;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.threeDanchor {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    display: inline-block;
    background: none;
}
/* COMMON */

#preventevent {
    pointer-events: none;
}

#preventevent svg {
    display: block;
}

#texttext {
    width: 100%;
    line-height: 1;
    overflow: hidden;
    resize: none;
    padding: 0;
    line-height: 1.3;
    height: auto;
    /* border: 1px solid #ddd; */
    border: none;
    background: none;
}
#texttext:focus,
#texttext:focus-visible {
    outline: none;
}

.pointer_events_none {
    pointer-events: none;
}

.fullHeight {
    height: 100%;
}

.titletext:hover .textWrap {
    outline: 1px dashed #000;
}
.titletext .textWrap.flexStart {
    justify-content: flex-start;
}
.titletext .icon_pencil_title_text {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 27px;
    align-items: center;
    justify-content: center;
    display: none;
    border-left: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    background: #fff;
    border-radius: 0;
}
.titletext:hover button.icon_pencil_title_text {
    display: block;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

/* 
BACKGROUND IMAGE 
PRESENTOR AND CUSTOMER LOGO
*/

.backgroundimage::after,
.slidelogos::after {
    content: ' ';
    position: absolute;
    top: -2px;
    /* for outside border */
    left: -2px;
    right: -2px;
    bottom: -2px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

.slidelogos2::after {
    content: ' ';
    position: absolute;
    top: -11px;
    /* for outside border */
    left: -11px;
    right: -2px;
    bottom: -10px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

#presentation_container .backgroundimage:hover::after,
.slidelogos:hover::after,
.slider-bottom-logo:hover::after {
    border: 2px solid #f59d08;
}

.backgroundimage .editbackgroundimage {
    padding: 0px;
    background: rgba(255, 255, 255, 0.6);
    display: none;
    height: 36px;
    /* margin-left: 80px!important; */
    border-radius: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    position: absolute;
    bottom: 30px;
    left: 0;
    margin-left: 40%;
}

.editbackgroundimage span {
    display: block;
    position: relative;
}

.editbackgroundimage span:after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #000;
    position: relative;
    bottom: -3px;
    display: block;
}

.backgroundimage:hover .editbackgroundimage {
    display: inline-block;
    padding: 0 15px;
}

/** Firefox fix */

/* .slidelogos {
  overflow: hidden;
} */

/* - LOGOS HOVER BUTTONS */

.productImages .logo-action,
.slidelogos .logo-action {
    top: 0px;
    right: 0;
    height: 27px;
    align-items: center;
    display: none;
    padding: 0 6px;
    z-index: 0;
    background: #fff;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.slidelogos2 .logo-action {
    top: -10px;
    right: 0;
    height: 29px;
    align-items: center;
    display: none;
}

.productImages:hover .logo-action,
.slidelogos:hover .logo-action {
    display: flex;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

.productImages .logo-action button,
.slidelogos button {
    background: #fff;
    /* width: 25px; */
    /* padding: 2px; */
    width: 17px;
    height: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: unset;
    margin-right: 8px !important;
}

.productImages .logo-action button:last-child,
.slidelogos button:last-child {
    margin-right: 0 !important;
}

.productImages .logo-action button img {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;
}

/*
rect
# image slice
*/

.rect-action {
    height: 27px;
    top: 2px;
    right: 2px;
    align-items: center;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    display: none;
    padding: 0 6px !important;
    background: #fff;
    z-index: 1;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.rect-action button {
    width: 17px;
    height: 25px;
    background: #fff;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 0;
    margin-right: 8px !important;
}

.rect-action button:last-child {
    margin-right: 0 !important;
}

.rect-action button:hover,
.rect-action button:active,
.rect-action button:focus {
    background: #fff !important;
    border-radius: 0;
}

.rect:hover .rect-action {
    display: flex;
}

.rect::after {
    content: ' ';
    position: absolute;
    top: 0px;
    /* for outside border */
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 2;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

.rect:hover::after {
    border: 2px solid #f59d08;
}

.rect.textfix::after {
    left: -148px;
}

.availablecolor {
    outline: 1px solid #ddd;
    display: flex;
}

.product_bottom_logo img,
.availablecolor img {
    width: 100%;
    height: 100%;
}

.slider-bottom-logo img {
    /* 
  PFSTOR-1242 to fix this
  width: 90% !important; 
  */
    height: auto;
    /* max-width: 100%; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.slider-bottom-logo .logo-action button img {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    transform: none;
    transform: initial;
}

.textEditable .flex.text.Wrap {
    min-height: 11.5px;
}

.textEditable .flex.text.Wrap:hover {
    outline: 1px dashed #000;
    align-self: baseline;
    min-height: 20px;
}

.textEditable input {
    height: 30px;
    border: 0;
}

.textEditable input:focus,
.textEditable input:active {
    outline: none;
}

.textEditable textarea {
    resize: none;
}

#textContainer {
    word-break: normal;
}

.contactText #textContainer {
    word-break: normal;
}

.contactText #textContainer p span {
    line-height: 24px;
    display: block;
}

.product-logo:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.product-logo .logo-action {
    top: 0;
}

.productImages {
    display: block !important;
    text-align: center !important;
}

.productImages img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
    margin: auto !important;
    display: inline-block;
    position: absolute;
    top: 49.9%;
    transform: translate(-50%, -49.9%);
    left: 50%;
    right: 50%;
}

/* .productImages::after
{
  content: ' ';
  position: absolute;
 
  border: 1px solid transparent;
  pointer-events: none;
  height: 100%;
  width: 100%;
 
} */

.productImages:hover {
    outline: 2px solid #f59d08;
}

.productImages .logo-action {
    background: #fff;
}

/* .productImages .logo-action button , .slider-bottom-logo .logo-action button
{
  padding: 0 3px;
} */

.productImages .logo-action button:hover,
.productImages .logo-action button:focus,
.productImages .logo-action button:active,
.slider-bottom-logo .logo-action button:hover,
.slider-bottom-logo .logo-action button:active,
.slider-bottom-logo .logo-action button:focus {
    background: #fff !important;
    border: none;
}

.nofocus input {
    border: 0;
}

.nofocus input:focus {
    outline: none;
}

.finalAction::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 10;
    border: 2px solid #f59d08;
    left: -146px;
    /* background: red; */
}

.finalAction .rect-action {
    display: block;
}

.ImagefinalAction::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 10;
    border: 2px solid #f59d08;
    left: -0px;
    /* background: red; */
}

.ImagefinalAction .rect-action {
    display: flex;
}

.nohoveryellow {
    /* cursor: pointer; */
    display: flex;
    /* justify-content: flex-end; */
    /* display: none; */
}
.nohoveryellow::after {
    display: none;
}
/* .nohoveryellow:hover {
    background: rgba(0, 0, 0, 0.1);
} */
.nohoveryellow button {
    /* display: none; */
    /* background: #ccc; */
    border-radius: 32px;
    padding: 0 15px;
    height: 32px !important;
    min-width: 32px;
    margin-top: 4px;
    margin-right: 5px;
    font-weight: 400;
    color: inherit;
    display: flex;
    overflow: hidden;
    width: 190px;
}
.nohoveryellow:hover button {
    /* display: block; */
    background-color: #e5e5e5;
}
.nohoveryellow button span.edittext {
    display: none;
    line-height: 32px;
}
.nohoveryellow:hover button span {
    display: block;
}
.nohoveryellow button i {
    line-height: 32px;
    margin-right: 10px;
    line-height: 32px;
    /* font-family: 'pfc-font-icon'; */
    /* content: '\41'; */
    /* height: 32px; */
    /* width: 32px; */
    /* color: inherit; */
}
.nohoverbackground > button {
    display: none;
    text-indent: -9999em;
}

/* TITLE TEXT */
.titletext {
    display: flex;
}

.titletext .textWrap:hover {
    outline: 1px dashed #000;
}

.titletext .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}
.titletext textarea,
.titletext textarea:focus {
    width: 100%;
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: 0;
}

.ground {
    /* display: none; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    height: 522px;
    width: 928px;
    overflow: hidden;

    /* width: 931px; */
    /* position: relative; */
}
/* .ground-old {
    display: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    height: 525px;
    width: 931px;
} */

.cc {
    /* width: 1280px; */
    /* height: 720px; */
    /* height: 526px; */
    /* width: 932px; */
    /* border: 2px solid #ccc; */
    background: #fff;
    overflow: hidden;
}
.cc > div {
    width: 928px;
    height: 522px;
}
/* .cc-old {
    height: 100%;
    width: 928px;
    outline: 2px solid #ccc;
    position: relative;
    background: #fff;
    overflow: hidden;
} */
.cc > div {
    width: 928px;
    height: 522px;
}

.ver-line-width {
    width: 1px !important;
}

.hor-line-height {
    height: 1px !important;
}

.contact_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
}
.contact_logo_warpper {
    width: 220px;
    height: 120px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);

    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
}

.contact_logo_warpper img {
    max-width: 100%;
    max-height: 100%;
}

.contact_name {
    font-size: 16px;
    font-weight: 400;
    width: 90%;
    text-align: center;
}

.contact_section {
    width: 300px;
    font-size: 14px;
}

.contact_section p {
    margin-bottom: 0;
    font-size: inherit;
    line-height: 18.2px;
    min-height: 18px;
}

.contact_section p span {
    display: inline-block;
    line-height: 1rem;
    width: 100%;
}
/* .contact_section .editable p:hover {
    outline: 1px dashed #000;
} */

/* .pillow .flex {
    outline: blue solid 1px;
} */
.pillow textarea {
    width: 100%;
    min-height: 10px;
    border: 0;
    outline: 0;
    padding: 0;
    /* overflow: hidden; */
    resize: none;
    display: inherit;
}

.pillow input {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0;
    display: inherit;
    /* min-height: 100px; */
    /* overflow: hidden; */
    /* resize: none; */
    height: auto;
}

.pillow input:focus,
.pillow textarea:focus {
    border: 0;
}

.available_color_image {
    /* display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 9px; */
    /* padding: 10px; */

    padding-left: 3px;
    font-size: 11px !important;
    width: 310px;
    /* min-height: 57px; */

    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px 0px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    line-height: 0;
}

.available_color_image > img {
    width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.available_color_image img {
    margin-left: 6px;
    margin-bottom: 6px;
}

.available_color_image::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}

.wfull {
    width: 928px;
}

.w350 {
    max-width: 356px;
    width: 356px;
    color: #fff;
}

.short_description_text {
    width: 275px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.short_description_text > textarea {
    font-size: inherit !important;
}
.short_descript_text textarea {
    font-size: 16px;
}

.long_description_text {
    /* width: 449.03px; */
    /* height: 184px; */
    /* P
    CC / Text
    */
    font-style: normal;
    font-weight: 325;
    font-size: 11px;
    line-height: 130%;
    /* or 16px */
    /* Neutral greys/White */
    color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}
.long_description_text textarea {
    font-family: inherit;
    font-size: inherit;
}

.green-point-cc {
    font-size: 9px;
    /* width: 78px; */
    min-width: 91px;
    height: 34px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 14.4384px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 14.4384px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
}
.green-point-cc img {
    width: 22px;
    height: 22px;
    position: absolute;
}

.green-number {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin-right: 12px;
    background: #ffffff;
    border: 2px solid #aad0c0;
    border: 1px solid #4a9d7d;
    color: #4a9d7d;
    position: relative;
    left: 14px;
}

.green-title {
    width: 36px;
    /* height: 26px; */
    font-size: 9px;
    /* Secondary/PFC Fresh Green */
    color: #4a9d7d;
    position: relative;
    right: -5px;
}

.available-color {
    /* width: 182px; */
    /* height: 23px; */
    /* H3
    CC / Label
    */
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    /* or 16px */
    /* Neutral greys/White */
    color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-left: 6px;
}
.available-color .text-bold_small {
    font-size: 11px;
}
.text-uppercase input {
    text-transform: uppercase;
}
.debtorLogo {
    width: 100px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
}

.elementActive,
.hoverFormELe:hover {
    outline: 1px dashed #fff;
}

/* DEBTOR LOGO */
.action-wrap:hover {
    outline: 2px solid #f59d08;
}

.action-wrap:hover .action-box {
    display: block;
}

.action-box {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    width: 56px;
    height: 27px;
    background: #fff;
    padding: 0 6px;
}

.action-box button {
    width: 17px;
    height: 25px;
}

.action-box button:hover {
    background: none;
}

.action-box button:first-child {
    margin-right: 8px;
}

.pillow-margin-backgorund {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 0 0;
    top: 482px;
    height: 40px;
    position: absolute;
    /* width: 280px; */
    /* width: 180px; */
}

@media print {
    /* .ground {
        height: 521px;
    } */
    /* .cc { */
    /* overflow: hidden; */
    /* border: 2px solid red; */
    /* } */
    .pillow-margin-backgorund {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px 0 0;
        /* bottom: -3px; */
        /* top: 482px; */
        height: 40px;
        position: absolute;
        margin-right: 0px;
        /* border: 1px solid blue; */
        /* width: 280px; */
        /* width: 180px; */
    }
}

/*
COLOR BOX
*/

.colorBoxWrap {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.colorBox {
    width: 610px;
    background: #fff;
    height: 440px;
    /* 430px; */
    display: flex;
    flex-direction: column;
    /* margin-top: -200px;
    margin-right: -145px; */
}

.colorBox > * {
    padding: 0 20px;
}

.colorBox__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #f1f1f1 solid;
    height: 50px;
    line-height: 50px;
}

.colorBox__title h4 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
}

.colorBox__title button {
    /* background: red; */
    width: 16px;
    height: 16px;
    position: relative;
    right: -10px;
    top: 10px;
}

.colorBox__title img {
    width: 16px;
    position: absolute;
    top: 0;
    right: 0;
}

.colorBox__body {
    flex: 1 1;
    padding-top: 20px;
    padding-bottom: 6px;
}

.colorBox__footer {
    flex: 0 0 50px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    /* border-top: 1px #f1f1f1 solid; */
    align-items: center;
}

#app_wrapper .colorBox__footer button {
    color: #000;
    margin-left: 10px;
    border: 1px solid #ccc;
    height: 34px;
    padding: 0 20px;
    background: #f4f4f4;
    border-radius: 30px;
    min-width: 100px;
    font-weight: normal;
}

#app_wrapper .colorBox__footer button:hover,
#app_wrapper .colorBox__footer button:active,
#app_wrapper .colorBox__footer button:focus {
    background: #f2f2f2 !important;
    border: 1px #ccc solid !important;
}

#app_wrapper .colorBox__footer button.ok {
    background: #fff;
    text-transform: uppercase;
}

.colorset {
    min-width: 50px;
    flex: 1 1;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px #cbcbcb solid;
    margin-right: 15px;
    padding-right: 15px;
}

.colorset .set {
    /* display: flex; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.colorset .set span {
    display: block;
    width: 16px;
    height: 16px;
    outline: solid 1px #000;
    margin-bottom: 5px;
    cursor: pointer;
}

.colorset span {
    display: block;
    width: 32px;
    height: 22px;
    outline: solid 1px #000;
}

.photoshop-picker {
    background: none !important;
    box-shadow: none !important;
}

.photoshop-picker > div:first-child {
    display: none;
}

.photoshop-picker .flexbox-fix {
    padding: 0 !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(1) {
    outline: 1px solid blue !important;
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:first-child {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(3) > div:nth-child(10) {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div {
    /* outline: 1px dashed red; */
    width: 160px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div span {
    width: 10px !important;
    height: 34px !important;
    line-height: 34px !important;
}
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:first-child,
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:nth-child(2),
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:nth-child(3) {
    background: yellow;
    top: -9999em;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(5) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(6) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(7) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) {
    /* top: -122px; */
    top: -75px;
    /* right: -85px; */
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child {
    height: 200px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div input {
    width: 44px !important;
    height: 34px !important;
    padding-left: 11px !important;
    font-size: 14px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div label {
    top: 6px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) input {
    width: 90px !important;
    height: 34px !important;
}

.pos-relative {
    position: relative;
    margin-bottom: 5px;
}
.fixcmyk {
    /* display: none; */
    position: relative;
    left: -160px;
    top: 5px;
}
.fixcmyk span {
    /* display: block; */
    position: absolute;
    text-transform: uppercase;
    left: 0px;
    top: 9px;
    font-size: 13px;
    width: 10px !important;
    height: 34px !important;
    /* outline: 1px solid red; */
}
.fixcmyk input {
    width: 44px !important;
    height: 34px !important;
    padding-left: 9px !important;
    font-size: 14px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}

/*
BACKGROUND IMAGE BOX
*/

.backgroundBoxWrap {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.backgroundBox {
    /* width: 740px; */
    width: 735px;
    background: #fff;
    /* height: 500px; */
    /* height: 624px; */
    min-height: 613px;
    display: flex;
    flex-direction: column;
}

.backgroundBox > * {
    padding: 0 20px;
}

.imageBtn {
    box-sizing: content-box;
    margin: 0 15px 14px;
    margin-left: 0;
    border: 2px solid #ccccca;
    width: 162px;
    border-radius: 0;
    height: 91px;
    overflow: hidden;
}

.imageBtn:nth-last-child(-n + 4) {
    margin-bottom: 0 !important;
}

#app_wrapper .imageBtn.active,
#app_wrapper .imageBtn:hover {
    /* margin: 5px; */
    border: 2px solid orange !important;
}

.imageBtn img {
    display: inline;
    float: left;
    width: 162px;
    height: 91px;
}

button.productBtn {
    /* width: 154px; */
    width: 166px;
    height: 166px;
    margin: 0 8px 8px;
    margin-left: 0;
    border-radius: 0;
    border: 1px solid #ccc;
}

button.folderIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 15px;
    width: 95px;
    color: black;
    font-weight: normal;
    font: 400 13.3333px Arial;
}

button.folderIcon:hover,
button.folderIcon:active {
    font-weight: normal !important;
    font: 400 13.3333px Arial !important;
    background: transparent !important;
}

button.folderIcon img {
    margin-bottom: 10px;
}

button.productBtn:nth-child(4n) {
    margin-right: 0;
    margin: 0;
}

#app_wrapper .productBtn.active,
#app_wrapper .productBtn:hover {
    border: 1px solid orange;
}

.productBtn img {
    display: inline;
    float: left;
    /* border: 2px solid #ccccca; */
    width: auto;
    max-width: 100%;
    height: inherit;
    max-height: 100%;
    height: auto;
}

/* .productBtn.active img,
.imageBtn.active img {
  border: 2px solid orange;
} */

.productBtn:hover,
.productBtn:active,
.productBtn:focus,
.imageBtn:hover,
.imageBtn:active,
.imageBtn:focus {
    border: 0;
    background: none;
    outline: none;
}

.backgroundBox__body {
    align-content: baseline;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    padding-top: 23px;
    padding-left: 23px;
    /* padding-bottom: 6px; */
    padding-right: 0;
    position: relative;
}

.imageBtn:nth-child(4n) {
    margin-right: 0;
}

.backgroundScrollWrap {
    display: flex;
    /* flex: 1 1; */
    flex-wrap: wrap;
    height: 442px;
    overflow: hidden;
    overflow-y: auto;
    /* max-width: 703px; */
    width: 703px;
    /* background-color: #ff0; */
    z-index: 2;
    align-content: flex-start;
    /* padding: 15px; */
    /* padding: 0 15px 0 0; */
}

.backgroundScrollWrapline {
    position: absolute;
    border: 1px solid #ccc;
    width: 695px;
    height: 447px;
    top: 20px;
    left: 20px;
    z-index: 1;
}
.backgroundSelectModal {
    /* background: yellow; */
    /* width: 755px; */
    width: 766px;
    /* height: 595px; */
}
/* .backgroundSelectModal.plus_delete {
	height: 650px;
} */

.backgroundSelectModal .backgroundBox__title h4 {
    line-height: 1.8em;
}
.backgroundSelectModal .backgroundBox__body {
    flex: 0 1;
}
.backgroundSelectModal .backgroundScrollWrap {
    margin-top: -10px;
    width: 722px;
    height: 422px;
}
@-moz-document url-prefix() {
    .backgroundSelectModal .backgroundScrollWrap {
        width: 730px;
    }
}
.backgroundSelectModal .backgroundScrollWrapline {
    top: 10px;
    width: 728px;
    height: 428px;
}
.backgroundSelectModal .backgroundBox__title {
    box-sizing: content-box;
    flex: 0 0 53px;
}

.backgroundScrollWrap::-webkit-scrollbar {
    width: 6px;
    padding: 4px;
}

.backgroundScrollWrap::-webkit-scrollbar-track {
    padding: 10px;
    background: #fff;
    padding: 0 10px;
}

.backgroundScrollWrap::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
    cursor: pointer;
}

.backgroundBox__body .productBtn img {
    float: none;
    max-height: 90%;
}

.backgroundBox__footer {
    flex: 0 0 50px;
    display: flex;
    justify-content: flex-end;
    /* border-top: 1px #f1f1f1 solid; */
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.backgroundBox__footer button {
    border-radius: 50px;
}

#app_wrapper .editBox .backgroundBox__footer.editBox__footer {
    padding: 30px 20px;
    border-top: none;
}

.backgroundBox__footer button {
    color: #000;
    margin-left: 10px;
    border: 1px solid #ccc;
    height: 35px;
    padding: 0 20px;
    background: #f2f2f2;
    border-radius: 30px;
    min-width: 100px;
    font-weight: normal;
}

.backgroundBox__footer button:hover,
.backgroundBox__footer button:active,
.backgroundBox__footer button:focus {
    border: 1px solid #ccc !important;
    transition: all 0.25s;
}

.backgroundBox__footer button.local__reset:hover,
.backgroundBox__footer button.local__reset:active,
.backgroundBox__footer button.local__reset:focus {
    border: none !important;
    color: #000;
}

.backgroundBox__footer button.ok {
    background: #fff;
    text-transform: uppercase;
}
.backgroundBox__footer button.upload.active {
    background: #fff;
}

.backgroundBox__footer button.local__reset {
    margin-left: 0;
    border: none;
    color: #15457e;
    padding: 0;
    background: transparent;
}

.upload-filename {
    font-size: 14px;
    font-family: Arial, sans-serif;
}

span.home {
    padding: 0 !important;
    width: 20px;
    float: left;
    margin-right: 10px;
    margin-top: -3px;
}

/* BACKGROUND COMPONENT CHANGE TO CSS */
.bbimage {
    padding: 10px 0 0 !important;
    margin: 0 20px 0;
}
.bbimage span {
    height: 20px;
    border-radius: 30px;
    padding: 0 0 0 10px;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: #19497a;
    cursor: pointer;
    font: 400 14px Arial;
}
.bbimage span:first-child {
    padding: 0;
}
.bbimage span.foldername:hover {
    text-decoration: underline;
}
.bbimage span:hover:after {
    text-decoration: none;
}
.bbimage span.noteffect::hover {
    text-decoration: none !important;
}
.bbimage span:last-child {
    color: #666666;
}
.bbimage span:last-child svg {
    fill: #666666;
}
.bbimage span:not(:last-child) svg {
    fill: #19497a;
}

.backgroundScrollWrap button {
    position: relative;
}
.backgroundScrollWrap button .delete-image-btn {
    display: none;
}
.backgroundScrollWrap button:hover .delete-image-btn {
    display: block;
}
.delete-image-btn {
    /* width: 40px;
	height: 50px;
	background: red; */
    display: block;
    position: absolute;
    right: 0;

    display: flex;
    padding: 0px 2px 2px 4px;
    align-items: flex-start;

    width: 35px;
    height: 27px;

    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    background: var(--neutral-greys-white, #fff);
}
.delete-image-btn > span {
    display: flex;
    width: 25px;
    height: 25px;
    display: block;
    padding: 4px 4px 5px 5px;
    justify-content: center;
    align-items: center;
}
.delete-image-btn img {
    width: 100%;
    height: 100%;
}

.confirm_overlay {
    position: fixed;
    z-index: 10000;
    background: rgb(0 0 0 / 50%);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.confirm_overlay_content {
    display: flex;
    width: 593px;
    padding: 20px 40px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: absolute;
    transition: top 0.4s ease-out;
    z-index: 10001;
    margin-left: -292px;
    margin-right: auto;
    left: 50%;
}

.confirm_overlay_content p {
    color: #000;
    margin-bottom: 20px;
}
.confirm_overlay_content svg {
    margin-right: 8px;
}

#app_wrapper .btn_delete_all:focus,
#app_wrapper .btn_delete_all:focus-visible,
#app_wrapper .btn_delete_all {
    display: flex;
    padding: 10px 22px 10px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    border: 1px solid #1e3968 !important;
    border: 1px solid var(--primary-pfc-dark-blue-500, #1e3968) !important;

    color: #1e3968;

    color: var(--primary-pfc-dark-blue-500, #1e3968);

    /* UI text/Semi-bold Medium */
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 100%; /* 14px */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    outline: none;
}
#app_wrapper .btn_delete_all:hover {
    background: transparent !important;
    color: #1e3968 !important;
}
#app_wrapper .btn_cancel {
    display: flex;
    padding: 10px 22px 10px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    color: #1e3968;
    outline: none;
}
#app_wrapper .btn_cancel:hover,
#app_wrapper .btn_canel:focus,
#app_wrapper .btn_cancel:focus-visible {
    background: transparent !important;
    color: #1e3968 !important;
    outline: none;
}
#app_wrapper .btn_cancel:hover {
    background: #f1f4f5 !important;
}
#app_wrapper .btn_ok,
#app_wrapper .btn_ok:active,
#app_wrapper .btn_ok:focus,
#app_wrapper .btn_ok:focus-visible,
#app_wrapper .btn_ok:disabled {
    display: flex;
    width: 92px;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: #1e3968;
    color: #fff;
    outline: none;
}
#app_wrapper .btn_ok:hover {
    background-color: #4b6186;
}

#app_wrapper .upload-images-btn,
#app_wrapper .upload-images-btn:active,
#app_wrapper .upload-images-btn:focus,
#app_wrapper .upload-images-btn:hover {
    outline: none;
    width: 167px;
    height: 34px;
    padding: 10px 22px 10px 17px;
    border-radius: 22px;

    border: 1px solid #1e3968;
    background: #fff;

    color: #1e3968;

    color: var(--Primary-PFC-Dark-blue---500, #1e3968);

    /* UI text/Semi-bold Medium */
    /* font-family: Gotham; */
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 100%; /* 14px */
}

.after_delete_all_screen_bg {
    background: #eee;
    border: 1px solid #ccc;
}

#app_wrapper .button {
    cursor: pointer;
	color: #fff;
	padding: 10px 22px;
	border-radius: 17px;
	font-size: 14px;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
    min-width: 92px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}
#app_wrapper .button:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #1e3968;
	border-radius: 10rem;
	z-index: -2;
}
#app_wrapper .button:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: #4b6186;
	transition: all 0.3s;
	border-radius: 10rem;
	z-index: -1;
}
#app_wrapper .button:hover {
	color: #fff;
}
#app_wrapper .button:hover:before {
	width: 100%;
}

#app_wrapper .button.btn-cancel {
    color: #1e3968 ;
	padding-left: 17px;
}
#app_wrapper .button.btn-cancel:after {
    background-color: #fff;
    /* color: #000; */
}
#app_wrapper .button.btn-cancel:before {
    background-color: #efefef;;
}

#app_wrapper .outer .button .text {
    display: flex;
    align-items: center;
}

.overlay_wrap {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1003;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
}
.modal_title,
.backgroundBox__title {
	box-sizing: content-box;
	display: flex;
	justify-content: space-between;
	/* background-color: red; */
	border-bottom: 1px #cccccc solid;
	height: 54px;
	line-height: 54px;
	/* box-sizing: border-box; */
}

.backgroundBox__title button:hover,
.backgroundBox__title button:active {
	background: transparent !important;
}
.modal_title h4,
.backgroundBox__title h4 {
	font-size: 18px;
	font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif;
	margin: 10px 0;
	color: #000;
	margin: 0;
	line-height: 54px;
}

.modal_title button,
.backgroundBox__title button {
	/* background: red; */
	width: 16px;
	height: 16px;
	position: relative;
	right: -10px;
	top: 10px;
}

.modal_title img,
.backgroundBox__title img {
	width: 16px;
	position: absolute;
	top: 0;
	right: 0;
}
.backgroudSelectBox {
    /* width: 615px; */
    width: 766px;
    height: auto;
    /* height: 600px; */
    /* min-height: 380px; */
    min-height: 440px;
    background-color: #fff;
}
.backgroudSelectBox .backgroundBox__title {
    min-height: 50px;
}

.backgroudSelectBox .backgroudSelectBox_body > div.flex,
.backgroudSelectBox_body {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-evenly;
}
.flex.justify-center.align-center.p-50 {
    padding: 0 15px;
}
.upload-choice {
    font-family: Arial, sans-serif;
}
.upload-choice h4 {
    line-height: 1.5;
    font-size: 18px;
}
.backgroudSelectBox .backgroudSelectBox_body > div.flex > div {
    /* margin: 0 20px; */
    text-align: center;
    flex: 1 1;
}
.backgroudSelectBox .backgroudSelectBox_body > div.flex > div h4 {
    margin: 0;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.backgroudSelectBox button {
    font-size: 14px;
    line-height: 18px;
    color: #231f20;
    border-radius: 0;
}
.backgroudSelectBox .backgroundBox__footer button {
    border-radius: 50px;
}
.backgroudSelectBox button:hover {
    background: none;
}
.backgroudSelectBox button {
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: normal;
}
.backgroudSelectBox button h4 {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1rem;
}
.backgroudSelectBox button > div {
    width: 132px;
    height: 132px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 25px #e8e8e8;
    /* box-shadow: 0px 0px 20px #dfdfdf; */
}
.backgroudSelectBox button > div:hover {
    /* box-shadow: 0px 0px 25px #f6f6f6; */
    box-shadow: 0 0 25px #ccc;
    transition: all 0.25s;
}

#preview {
    height: 75px;
    padding-right: 20px;
}

.upload-form {
    width: 415px;
    margin: 0 auto;
    position: relative;
}
.upload-form .uploadPreview {
    height: 115px;
    display: flex;
    align-items: center;
    /* outline: 1px solid red; */
}
.uploadPreview .upload-button-warp {
    height: 60px;
    justify-content: left;
}
p.upload-err {
    position: absolute;
    top: 15px;
    width: 100%;
    font-size: 12px;
    /* border: 1px solid red; */
    text-align: center;
    line-height: 1.6rem;
}
p.upload-err span {
    display: block;
}
p.upload-err + .upload-button-warp > .upload-button {
    margin-top: 30px;
}
p.upload-err + .uploadPreview {
    margin-top: 30px;
}
.upload-button-warp {
    height: 115px;
    max-height: 115px;
    overflow: hidden;
    /* outline: 1px solid black; */
}
.upload-button {
    border: 1px solid #cccccc;
    padding: 10px 22px 10px 17px;
    border-radius: 25px;
    height: 34px;
    line-height: 14px !important;
    display: flex;
    text-align: center;
    justify-content: flex-start;
    min-width: 185px;
    font-size: 14px;
    font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif !important;
}

.backgroudSelectBox button.upload-button {
    border-radius: 25px;
}
.cc-upload-button img {
    margin-right: 15px;
}
.upload-button:hover,
.upload-button:active,
.upload-button:focus {
    border: 1px solid #034883 !important;
    transition: all 0.25s;
}
#cc-upload-file {
    display: none;
    position: absolute;
    top: 0;
    opacity: 0;
}
.upload-requirements {
    background-color: #f2f2f2;
    padding: 20px 22px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}
.upload-requirements h4 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    font-family: "Conv_Gotham-Medium", Verdana, Arial, sans-serif;
}
.upload-requirements ul {
    list-style: none;
    margin-left: 0;
    margin-top: 12px;
    font-size: 14px;
    font-family: Arial, serif;
    margin-bottom: 0;
    margin-top: 5px;
}
.upload-requirements ul li {
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: normal;
    font-family: Arial, sans-serif;
}
.upload-requirements ul li:last-child {
    margin-bottom: 0;
}
.upload-err {
    color: red;
}

.upload-note {
    font-family: Arial, serif;
    font-size: 14px;
    line-height: 1.4em;
    padding: 15px 18px;
    border: 1px solid #f2f2f2;
    margin-top: 20px;
}

.edittogglewrap {
    padding: 20px 0 0;
}
.edittoggle {
    width: 30px;
    height: 12px;
    border-radius: 6px;
    background: #999;
    position: relative;
    cursor: pointer;
    top: 1px;
}
.edittoggle span {
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid #cacaca;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    top: -3px;
    box-shadow: 0 0 3px #a9a9a9;
}
.edittoggle.on {
    background: #8ccdef;
}
.edittoggle.on span {
    background: #044680;
    border: 1px solid #013a6c;
}
.edittoggle.on span {
    right: 0;
}
.edittoggle.off span {
    left: 0;
}
.backgroundBox__body p.p-edittoggle {
    padding: 0;
    margin-left: 10px;
}
.modal-open {
    overflow: hidden;
}

.editBoxWrap {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    width: 100%;
    /* 1151px; */
    /* 1143px; */
    /* height: 100%;     */
    min-height: 100%;
    /* 615px; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    overflow-y: auto;
    padding: 30px 0 30px;
}

.editBoxWrap::-webkit-scrollbar {
    width: 6px;
    padding: 4px;
}

.editBoxWrap::-webkit-scrollbar-track {
    padding: 10px;
    background: #fff;
    padding: 0 10px;
}

.editBoxWrap::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
    cursor: pointer;
}

.editscroll {
    min-height: 100px;
}

.editBox {
    width: 750px;
    background: #fff;
    height: auto;
    margin: 0 auto;
    /* display: flex; */
    /* flex-direction: column; */
    /* min-height: 90vh; */
}

.editBox>* {
    padding: 0 20px;
}

.editBoxWrap .editBox .backgroundBox__title button img {
    position: absolute;
    top: 0;
    right: 0;
}

.editBoxWrap .editBox .backgroundBox__body {
    /* min-height: 300px; */
    display: block;
    /* flex-grow: 1; */
    /* flex-shrink: 0; */
    flex: 0 0;
    /* overflow-y: auto; */
    /* overflow: overlay; */
}

.backgroundBox__body p {
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.3;
}
.backgroundBox__body p+p {
    padding-bottom: 20px;
}

.backgroundBox__body table {
    width: 100%;
    border: 0;
}
.backgroundBox__body input[type=checkbox] {
    opacity: 1 !important;
    height: 13px !important;
    width: 13px !important;
    position: relative;
    z-index: 1;
}

#app_wrapper .backgroundBox__body button.delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEwQTc4ODg1NTQ4NDExRUJCOEE2QzcxMDBFRDQ3MkIyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEwQTc4ODg2NTQ4NDExRUJCOEE2QzcxMDBFRDQ3MkIyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTBBNzg4ODM1NDg0MTFFQkI4QTZDNzEwMEVENDcyQjIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTBBNzg4ODQ1NDg0MTFFQkI4QTZDNzEwMEVENDcyQjIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4isd/QAAABBklEQVR42mzRz0sCQRTA8Z3YJH/kwTRvKtklvHixU4c6CP0PdvDk/+HZP8RrB8GTHiSDbh3Cg6IhCGqpEJRkgut34A2tugMfZve9eTNvdm3HcSyllGXGRTLZYMphAxuPg+HwweRtWXTNFJBYBiVMkEWR/K3kpkqfkE6larzc4wlhfLtOOMEPblBRpiV2+ZNkBHMkMMIZHLzRWvzI+h8LRFHHHXrIoyrxmV7kLphJYo1THCOEFWK6//2CMc5lQUhiel4ijg+vE3TBF/wSMx/As6VPSfzCJ7GgbBCTz7xTMJUTnvGufxi6eJGWPC99hZbcp4w+Org0BbaroIk0CtbheEVbP2wFGAA3DkTOj4EKigAAAABJRU5ErkJggg==
) no-repeat center center;
    height: 100%;
    width: 100%;
}

#app_wrapper .backgroundBox__body button.delete:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJGQjU1QjdBNTQ4NDExRUI4ODAwQzdFNTUzMTY2NzNGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJGQjU1QjdCNTQ4NDExRUI4ODAwQzdFNTUzMTY2NzNGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkZCNTVCNzg1NDg0MTFFQjg4MDBDN0U1NTMxNjY3M0YiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkZCNTVCNzk1NDg0MTFFQjg4MDBDN0U1NTMxNjY3M0YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6jK0vFAAAA/0lEQVR42mzRv2vCQBTAcVNSqVYdaqKbS12Ki4tODu0g+D/o4OT/4ewf4uogdKqD2EK3DuKgiIvQpr0WClr8AcbvwQtcNQcfLnnvXu7dxfJ9P2IOpdQTUwkH2Og5jtMI8rYsKjPFJVZACx8ookn+XnKepXcg0OelhhFSWBk7XGGNCjpW0BJFO0ne4Bs5LJGGXjSmteyF0f4PHDziATNU0ZW40ovMAiWJPZK4RAJbuLr/04J3ZGRBQmJ6/kMWn2E76IJfxCQWXEBoS1+S2CAqsWv5gCvX/K/Akx1esNA/DFO8Skuhh77DUM7TxhwT5IMC2ygY4Bb1yPl4w7N+OAowANI0S+44aRVYAAAAAElFTkSuQmCC);
    background-color: orange;
    border-radius: 0;
}

#app_wrapper .backgroundBox__body button.add {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFGNDJENjkxNTQ4NDExRUI4Njg0QjdBQjc2MkNBRkE2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFGNDJENjkyNTQ4NDExRUI4Njg0QjdBQjc2MkNBRkE2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUY0MkQ2OEY1NDg0MTFFQjg2ODRCN0FCNzYyQ0FGQTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUY0MkQ2OTA1NDg0MTFFQjg2ODRCN0FCNzYyQ0FGQTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6tZOksAAAAoklEQVR42mL8//8/AymABUQwutWiizcCsR4QRwDxT5jg/13NDEw4DFIFYjUgZkOXwKXhB9Tkf8Rq+AXEf4D4K1Y/AEEFEEsD8W8g/gvE1kAsCsQToBrZoIaUwDTYALEu1Cl/oZrZgdgL6iyQhi/INoQDMR/UBhCeBA0lL6hfWKE2wDV8RXPvL6hNb2EKCXmaA+okDmJD6QuSLSiAkdSkARBgAFR8Kgopg2MrAAAAAElFTkSuQmCC) no-repeat center center;
    height: 100%;
    width: 100%;
}

#app_wrapper .backgroundBox__body button.add:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE5QzM1Nzg1NTQ4NDExRUJBNEU0QzUwMkVDRUMxM0JFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE5QzM1Nzg2NTQ4NDExRUJBNEU0QzUwMkVDRUMxM0JFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTlDMzU3ODM1NDg0MTFFQkE0RTRDNTAyRUNFQzEzQkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTlDMzU3ODQ1NDg0MTFFQkE0RTRDNTAyRUNFQzEzQkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5WrhVAAAAAl0lEQVR42mL8//8/AymACYd4IxCvB2J2YjWoArEaELMRq+EHEP8E4n/EavgFxH+A+Cu6BCPU0xVALA3Ev4H4LxB7ALEoEK+AamSDGlLCAtVoA8S6UKf8hWoGedgL6iyQhi/INnADMR/UBhCeBMR6UA0gv7BCbXgPs+Ermnt/QW16C2UT9DQH1EkcxIbSFyRbsIYS0QAgwABLqSz7Bm0N1gAAAABJRU5ErkJggg==);
    background-color: orange;
    border-radius: 0;
}


/* #app_wrapper .backgroundBox__body button.add:disabled { background: red; } */

.ecqEditbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.ecqEditbutton:hover {
    background: rgba(0, 0, 0, .25);
}

.ecqEditbutton button {
    display: none;
    bottom: 30px;
    /* left: 50%; */
    height: 36px;
    /* margin-left: -80px !important; */
    width: 168px;
    border-radius: 36px;
    padding: 0px;
    background: rgba(255, 255, 255, .6);
    /* display: block; */
    font-size: 14px;
    font-weight: normal;
    color: #000;
    margin: auto !important;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: "Conv_Gotham-Book", sans-serif !important;
}

.ecqEditbutton:hover button {
    display: inline;
}

.ecqEditbutton span {
    border-bottom: 1px #000000 dashed;
}

.ecqEditbutton button::after {
    content: '';
    width: 110px;
    height: 31px;
    border-bottom: 1px dashed #000;
    position: absolute;
    top: -3px;
    left: 31px;
    display: block;
}

.backgroundBox__body table th {
    height: 48px;
    background-color: #c7332c;
    color: #fff;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
}

.backgroundBox__body table th:first-child {
    text-align: left;
}

.backgroundBox__body table td:first-child img {
    outline: 1px solid #c3c3c3;
}

td.tderror,
.backgroundBox__body table tbody td:nth-child(3).tderror
{
    background: rgba(255,0,0,.3);
}
.backgroundBox__body p.errorform {
    padding: 0;
    color: red;
    margin-bottom: 20px;
    line-height: 1.6em;
}

.backgroundBox__body table th.green-one {
    background-color: #0f7a75;
    font-size: 14px;
    line-height: 1.3;
}

.backgroundBox__body table th.green-two {
    background-color: #0e746f;
}


/* .backgroundBox__body table tbody{
  border: 1px #808080 solid;
  
} */

.backgroundBox__body table tbody td {
    border: 1px #808080 dashed;
    border-right: 0;
    border-bottom: 0;
    height: 29px;
    font-size: 12px;
    padding: 0;
    vertical-align: middle;
}

.backgroundBox__body table tbody td:last-child {
    border: 1px #808080 dashed;
    border-bottom: 0;
}

.backgroundBox__body table tbody span.col {
    width: 17px;
    height: 17px;
    background: red;
    display: block;
}

.backgroundBox__body table tbody td:first-child {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    min-width: 220px;
    padding: 0 10px 0 0;
}

.backgroundBox__body table tbody td:first-child .EditableItemCell {
    display: inline-block;
}

.backgroundBox__body table tbody td:first-child img {
    float: right;
}

.backgroundBox__body table tbody td:nth-child(2) {
    background-color: #e1e1e1;
    width: 75px;
    text-align: center;
}

.backgroundBox__body table tbody td:nth-child(3) {
    background-color: #eeeeee;
    text-align: center;
    width: 74px;
}

.backgroundBox__body table tbody td:nth-child(4) {
    /* color: #fff;
    background-color: #071639; */
    color: #000;
    background-color: #e1e1e1;
    border-style: solid;
    text-align: right;
    min-width: 76px;
    padding: 0 10px;
    vertical-align: middle;
}

.backgroundBox__body table tbody td:nth-child(5),
.backgroundBox__body table tbody td:nth-child(6) {
    background-color: #fdf7b3;
    text-align: center;
    width: 74px;
}

.backgroundBox__body table tbody td:nth-child(5) .editablebox {
    background: transparent;
}

.backgroundBox__body table tbody td:nth-child(6) {
    border-style: solid;
    border: 0;
    border-top: 1px #d3d3d3 solid;
    border-left: 1px #d3d3d3 dashed;
    width: 76px;
}

.backgroundBox__body table tbody td:nth-child(7) {
    border-style: solid;
    border: 0;
    text-align: center;
    border-top: 1px #d3d3d3 solid;
    background-color: #f0eaaa;
    width: 32px;
}

.backgroundBox__body table tbody tr.tblSpace td {
    border-bottom: 1px #808080 dashed;
    border-bottom: 0;
    border-left: 0;
    height: 30px;
}

.backgroundBox__body table tbody tr.tblSpace td:first-child {
    border-bottom: 2px solid #c7332c;
}

.backgroundBox__body table tbody tr.tblSpace td:nth-child(2),
.backgroundBox__body table tbody tr.tblSpace td:nth-child(3) {
    border-bottom: 2px solid #071639;
}

.backgroundBox__body table tbody tr.totalExcVAT td {
    border: 0;
    height: 47px;
    font-size: 18px;
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.totalExcVAT td:nth-child(2) {
    text-align: right;
    padding-right: 10px;
    vertical-align: middle;
    background: #eee;
}

.backgroundBox__body table tbody tr.pricePerProduct td {
    font-size: 18px;
    border: 0;
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.pricePerProduct td:first-child {
    background: #c7332c;
    color: #fff;
    height: 48px;
}

.backgroundBox__body table tbody tr.pricePerProduct td:nth-child(2) {
    /* background: #071639;
    color: #fff; */
    background: #e1e1e1;
    color: #000;
    height: 48px;
    text-align: right;
    padding-right: 10px;
    vertical-align: middle;
}

.backgroundBox__body table tbody tr td.bordernone {
    border-right: 0;
    border-left: 0;
}

.backgroundBox__body table tbody tr td.tdwhite {
    background: #fff;
    color: #000000;
    padding: 0;
}

.backgroundBox__body table tbody tr td.tdyellow {
    background: #fdf7b3;
    border-style: dashed;
}

.backgroundBox__body table tbody tr td.tdacttion {
    background: #f0eaaa;
}

.backgroundBox__body table tbody tr td.tdgret {
    background: #eee;
    color: #000;
}

.backgroundBox__body table tbody tr.more-items td {
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.more-items td:nth-child(4) {
    background-color: #eee;
    color: #000;
}

.backgroundBox__body table tbody tr.more-items td:nth-child(7) {
    padding-left: 0;
}

.EditableCell {
    height: 100%;
    width: 75px;
}

.EditableItemCell input,
.EditableCell input {
    height: 100%;
    width: inherit;
    border: 0;
    background: 0;
    text-align: center;
    font-size: 12px;
}

.EditableItemCell input {
    text-align: left;
    padding-left: 10px;
}

.EditableItemCell input:focus,
.EditableCell input:focus {
    outline: 0;
}

#app_wrapper .editBox__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

#app_wrapper .editBox__footer .local__reset,
#app_wrapper .editBox__footer .local__reset:hover {
    /* border: 0; */
    padding: 0;
    border: 0;
    background: none;
    border: none !important;
}

.editablebox {
    height: 28px;
    background: transparent;
}

.editablebox_input,
.editablebox_input:focus {
    border: 0;
    outline: none;
    height: 100%;
    width: 64px;
    padding-left: 10px;
    text-align: center;
    background: transparent !important;
}


/* Chrome, Safari, Edge, Opera */

.editablebox_input::-webkit-outer-spin-button,
.editablebox_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

.editablebox_input[type=number] {
    -moz-appearance: textfield;
}

.editablebox .editablebox_action {
    flex-direction: column;
    width: 14px;
}

.editablebox .editablebox_action img {
    display: none;
    cursor: pointer;
}

.editablebox:hover .editablebox_action img {
    display: flex;
}


/* EDITBLE FORM */

.editableformWrap {
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

.editableform {
    /* position: absolute; */
    background-color: #fff;
    width: 400px;
    height: 420px;
    z-index: 11;
    display: block;
    flex-direction: column;
}

.editableform>div {
    padding: 0 20px;
}

.editableform .editableform__body {
    padding: 10px 20px;
    flex: 0 1;
}

.editableform .editableform__body .formEle {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
}

.editableform .editableform__body label {
    width: auto;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

.editableform .editableform__body input[type=text],
.editableform .editableform__body input[type=number] {
    height: 30px;
    border: 1px #ccc solid;
    margin-bottom: 5px;
}
.emailshare {
    display: table;
    padding-top: 10px;
}

.emailshare>div {
    display: flex;
    margin-top: 20px;
    padding: 0 20px 0 70px;
    /* outline: 1px solid blue; */
}

.emailshare>div>div {
    flex: 1.2 1;
    padding-top: 10px;
}

.emailshare>div>div:nth-child(2) {
    flex: 2 1;
    padding-top: 0;
}

.emailshare input,
.emailshare textarea {
    height: 100%;
    width: inherit;
    border: 0;
    background: 0;
    /* text-align: center; */
    padding: 10px;
    font-size: 12px;
    border: 1px solid #000;
    display: flex;
    width: 100%;
}

.emailshare textarea {
    height: 140px;
    resize: none;
}

.mailsharevalue {
    display: flex;
    background: #f1f1f1;
    padding: 10px 25px 10px 15px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.mailsharevalue input {
    border: 0;
}

.mailsharevalue input:focus {
    outline: none;
}

.mailsharevalue button {
    color: #05457C;
}

.mailsharevalue button,
#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    background: transparent !important;

}

#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover #presentation_container .mailsharevalue button:active {
    color: #00457c;
}

.emailshare p {
    position: absolute;
    bottom: 12px;
    left: 100px;
}

.downloadBox {
    font-size: 16px !important;
    color: #05457C;
    display: flex;
    justify-content: space-evenly;
}

.downloadBox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 138px;
    flex-direction: column;
    grid-gap: 34px;
    gap: 34px;
}

.downloadBox-icon {
    height: 138px;
    width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editBox-width {
    width: 735px !important;
    grid-gap: 30px !important;
    gap: 30px !important;
    display: flex !important;
    flex-direction: column !important;
}

.pdf-download>.downloadBox-icon {
    border: 1px solid #E2E2E2;
    border-radius: 50%;
}

.downloadBox-share {
    margin: 0px !important;
    height: 227px;
    border: 1px solid #E2E2E2;
}

.emailshare-size {
    padding: 0px !important;
}

.emailshare-size>div {
    padding: 0px 30px !important;
    margin-top: 0px !important;
}

.mailsharevalue-size {
    padding: 10px 35px 12px 12px !important;
    margin-bottom: 30px !important;
    background-color: #F6F6F6 !important;
}

.mailshare-link {
    font-weight: 400 !important;
    font-size: 16px !important;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    text-transform: capitalize !important;
}

.title-size h4 {
    margin-top: 0px !important;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: 'Conv_Gotham-Bold', Verdana, Arial, sans-serif;
}

.mailshare-font {
    font-size: 16px !important;
    max-width: 412px;
    overflow: hidden;
}

.downloadBox,
.mailshare-link {
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif;
}

.mailsharevalue-size>input {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif;
}

.share-header {
    font-weight: 400 !important;
}
.message_overlay {
    background: rgba(0, 0, 0, 0.5);

    /* position: absolute; */
    position: fixed;
    z-index: 1004;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
}
.message_box {
    min-width: 400px;
    min-height: 120px;

    background: #fff;
    padding: 20px 40px;
    transition: top 0.4s ease-out;

    position: absolute;
    top: -100%;
}
.message_box p {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
}

/* .message_footer button.btn-cancel, */
/* #presentation_container .message_footer button.btn-cancel:active { */
/*     display: flex; */
/*     padding: 10px 22px 10px 17px; */
/*     justify-content: center; */
/*     align-items: center; */
/*     border-radius: 17px; */
/*     color: #1e3968; */
/*     outline: none; */
/* } */
/* .message_footer button.btn-cancel > svg { */
/*     margin-right: 8px; */
/* } */
.message_footer button.btn-ok,
#presentation_container .message_footer button.btn-ok:active {
    font-size: 14px;
    display: flex;
    width: 92px;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: #1e3968;
    color: #fff;
    outline: none;
}
.message_footer .btn-cancel svg {
    margin-right: 8px;
}

.cc-title {
  background: #1e3968;
  height: 82px;
}
/* .cc-title > div {
    height: 52px;
} */
/* .cc-tlogo svg {
    height: 52px;
    width: 52px;
} */
.cc-ttitle {
  height: 36px;
  font-size: 30px;
  line-height: 36px;
}
.cc-tinput {
  color: #fff !important;
  /* font-family: Gotham; */
  font-size: 30px !important;
  font-style: normal;
  font-weight: 450;
  line-height: 36px; /* 120% */

  background: none !important;
  border: 0 !important;
  outline: none !important;
}
.cc-title button.pfc-bright-blue {
  width: 115px;
  height: 44px;
}

#presentation_container .action.btn-icon.btn-drp:active {
  background: #3082f9;
  color: #fff;
}

.action.btn-icon.btn-drp:hover {
  background-size: 0 100% !important;
}
.action.btn-icon.btn-drp {
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: center;
}

.action.btn-icon.btn-drp div {
  display: flex;
  align-items: center;
}

.action-btn-container .drp-container {
  font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}
.action-btn-container .drp-container label {
  display: flex;
  padding: 10px;
  white-space: nowrap;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 217px;
}

.flipsnack-title {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.flipsnack-content {
  color: #000;
  white-space: pre-wrap;
  font-size: 12px;
  padding: 0 10px;
  line-height: 1;
  font-weight: 400;
  font-family: "Gotham-Book", Verdana, Arial, sans-serif;
}

.action-btn-container svg {
  transition: transform 0.4s ease-in-out;
}

.action-btn-container .drp-container label:hover {
  background: #f1f4f5;
  border-radius: 5px;
}
.action-btn-container .drp-container.hide {
  display: none;
}
.action-btn-container .drp-container.show {
  opacity: 1;
}
.action-btn-container .drp-container {
  opacity: 0;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #0000001f;
  box-shadow: 0px 10px 15px -5px #0000001f;
  top: 55px;
  z-index: 3;
  transition: opacity 0.4s ease-in-out;
}
.action.btn-icon.btn-drp .arrow-container {
  margin-left: 20px;
  border-left: 1px solid #ffffff80;
  padding: 2px 0px 2px 12px;
  height: 16px;
}

.new-indicator {
  background: #f59d08;
  color: #fff;
  border-radius: 2px;
  padding: 4px 6px;
  font-size: 12px;
  font-family: "Gotham-bold", Verdana, Arial, sans-serif;
}

.rotate-180deg svg {
  transform: rotate(180deg);
}

.flipsnack-container,
.flipsnack-title {
  display: flex;
}

.flipsnack-container {
  flex-direction: column;
}

.c-p-t-list {
    list-style: none;
    padding: 0;
    padding-left: 0 !important;
}
.c-p-t-list li {
    border: 1px solid #eee;
    padding: 2px 10px 2px 2px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-p-t-list li div {
    width: 99px;
    /* background: red; */
    height: 100%;
}
.cc2-aside-panel-theme {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 30px; */

    /* background: #ccc; */
    height: 90%;
    overflow: hidden;
}

.cc2-aside-panel-theme-tabs {
    width: 100%;
    /* 284px; */
    display: flex;
    /* background-color: #ccc; */
    height: 90px;
}

.cc2-aside-panel-theme-tabs > * {
    flex: 1 1;
    text-align: center;
    /* height: 80px; */
    /* background-color: #f00; */

    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 120%; /* 19.2px */
}
.cc2-aside-panel-theme-tabs div span {
    position: relative;
    /* border-bottom: 3px solid orange; */
    /* height: 50px; */
    /* text-underline-offset: 1.6em; */
    /* text-decoration: underline; */
    /* display: block; */
    line-height: 90px;
    cursor: pointer;
}
.cc2-aside-panel-theme-tabs > div.active span:after {
    content: " ";

    width: 100%;
    position: absolute;
    left: 0;
    top: 19px;
    bottom: 8px;
    border-width: 0 0 2px;
    border-style: solid;
    height: 8px;
    border-color: #f59d08;
}

.cc2-aside-panel-theme-ul {
    width: 100%;
    /* width: 284px; */
    /* background: #f00; */
}

.cc2-aside-panel-theme-ul li {
    list-style: none;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    border: 1px #ccc solid;
    background: #fff;

    display: flex;
    height: 50px;
    padding: 2px 10px 2px 2px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.cc2-aside-panel-theme-ul li:last-child {
    margin-bottom: 0;
}

.c-p-t-list li.active,
.cc2-aside-panel-theme-ul li.active {
    border: 2px solid #3082f9;
    background-position: 0 0 !important;
}

/*
THEME COLOR DISPLAY
cc2-aside-panel-theme-ul-li-color
*/
.cc2-aside-panel-theme-ul-li-color {
    display: flex;
}
.cc2-aside-panel-theme-ul-li-color span {
    width: 20px;
    height: 46px;
    display: block;
    text-indent: -9999em;
}
.cc2-aside-panel-theme-ul-li-color span:first-child {
    background: #260101;
}
.cc2-aside-panel-theme-ul-li-color span:nth-child(2) {
    background: #f27405;
}
.cc2-aside-panel-theme-ul-li-color span:last-child {
    background: #f2bb16;
}

/* .c-s-p-element > div { */
/*     outline: 1px solid red; */
/* } */

.cc2-aside-panel-element {
  padding: 0 30px;
}
.cc2-style_wrap {
  /* background: red; */
  margin-bottom: 20px;
}

.cc2-color-panel {
  background-color: #eee;
  padding: 20px;
  position: relative;
}
.down-arrow {
  width: 0;
  height: 0;
  margin: 8px 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #eee;
  position: absolute;
  bottom: -20px;
  left: 127px;
}
.cc2-style-item {
  width: 68px;
  height: 68px;
  background-color: #fff;
  border: 2px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 20px;
}

.cc2-style-item.active {
  border-color: #3082f9;
}

.cc2-setting__divider {
  border-top: 1px #666 dashed;
  padding-top: 20px;
}

.highlight-item {
  width: 64px;
  height: 64px;
  background-color: #fff;
  border: 2px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 20px;
  padding: 4px;
}
.highlight-item.active {
  border-color: #3082f9;
}
.highlight-item > img {
  max-width: 60px;
  max-height: 60px;
}

/* COLOR */
.setting__element__color span,
.setting__element__colorpicker {
  /* width: 10px; */
  /* height: 30px; */
  /* background-color: red; */
  border: 1px solid #000;
}
.setting__element__colorpicker {
  width: 36px;
  height: 36px;
  display: block;
  margin-left: 10px;
}
.mainColor .setting__element__colorpicker {
  width: 56px;
  height: 36px;
  margin-left: 0;
}

.colorChoice > div {
  cursor: pointer;
  height: 20px;
  /* line-height: 20px; */
  font-size: 16px;
}
.colorChoice > div > SVG {
  margin-right: 10px;
}

/* COLOR :: SCHEME */
.colorScheme-item {
  width: 68px;
  height: 24px;
  padding: 2px;
  background: #fff;
  /* border: 2px solid red; */
  /* outline: 1px solid red; */
  margin-right: 20px;
}
.colorScheme-item > div {
  /* border: 1px solid blue; */
  /* padding: 2px; */
  width: 64px;
  height: 20px;
}
.colorScheme-item:nth-child(3n + 0) {
  margin-right: 0;
}
.colorScheme-item-color {
  width: 68px;
  padding: 0;
}
.colorScheme-item-color > span {
  height: 20px;
  width: 20px;
}
.colorScheme-item.active {
  padding: 0;
  border: 2px solid #3082f9;
}

/**/
.c-box-wrap {
  padding: 15px 20px 5px 20px;
  border: 1px solid #e2e2e2;
}
.color-deletable {
  position: relative;
}
.color-deletable .color-delete-btn {
  width: auto;
  height: auto;
  display: inline-flex;
  background: #fff;
  position: absolute;
  right: -17px;
  bottom: 12px;
  border-radius: 50%;
  padding: 3px;
  display: none;
  padding: 6px;
  border: 1px solid #00000033;
}
.color-deletable:hover .color-delete-btn {
  display: inline-flex;
}
button.action.bgColorNone {
  height: 34px;
  padding: 10px 22px 10px 17px;
  background: none !important;
  width: 100%;
}
button.action.bgColorNone:active {
  /* border: 1px solid red !important; */
  border: 1px #1e3968 solid !important;
}
#presentation_container button.action.bgColorNone:hover {
  background: none;
  border: 1px #1e3968 solid;
}

/* .cc2-aside-panel-design { */
/* 	padding: 20px 30px; */
/* } */
.c-s-p-element ul {
    list-style: none;
    padding: 0;
}
.c-s-p-element li {
    margin-bottom: 10px;
}

/* .cc2-side-design-list li:last-child { */
/*     margin-bottom: 0; */
/* } */
.font-color {
    /* LAYOUT */
    width: 54px;
    height: 36px;
    /* STYLE */
    border: 1px solid #000;
    border: 1px solid var(--neutral-greys-black, #000);
    background: #fff;
}

.setting__element {
    height: 555px;
    /* 545px; */
    /* width: 261px; */
    /*  overflow-y: hidden;*/
    overflow-x: hidden;
    overflow-y: auto;
}
.setting__element__themes {
    height: 505px;
}

/* .setting__element:hover {
  overflow-y: auto;
} */

.setting__element__wrap {
    /* width: 251px; */
    width: 100%;
    padding: 20px;
    background: #f2f2f2;
    /* padding-right: 10px; */
}
/* .setting__element__wrap > div {
  outline: 1px solid red;
} */

h3.setting__element__title {
    color: #000000;
    font-style: normal;
    font-weight: 450;
    font-weight: bold;
    font-size: 16px;
}
/* 
* Left menu, element panel
* list have different space between second and last item
*/
.ele-list-design > div:nth-child(2) {
    margin-bottom: 30px !important;
}
.ele-list-design > div:last-child {
    margin-top: 30px;
}
.ele-list-design > div:nth-last-child(2) {
    margin-bottom: 0;
}

.cc2-margin {
    background: red;
}

.cc2-aside-panel-margin {
    padding: 20px 30px;
}

.margin-input {
    /* LAYOUT */
    display: flex;
    width: 57px !important;
    height: 40px !important;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    margin-right: 5px;
    /* STYLE */
    border: 1px solid #e2e2e2;
    background: #fff;
    text-align: right;
}

#presentation_container .margin-ok-btn,
#presentation_container .margin-ok-btn:active,
#presentation_container .margin-ok-btn:focus {
    /* LAYOUT */
    display: flex;
    padding: 14px 25px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    /* STYLE */
    background: #1e3968;
    border-radius: 22px;
}

.margin_bottom_text {
    width: 100%;
    background-color: #dfede0;
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: center;
    padding: 18px;
    font-size: 12px;
    color: #006200;
    border-top: 1px solid #cadfd0;
}

.setting__element__color__bg {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 24px;
  width: 24px;
  /* 20px; */
  height: 24px;
  /* 20px; */
  background: #000;
  cursor: pointer;
  border: 1px solid #000;
  margin: 0px;
  box-sizing: border-box;
}

.setting__element__color__bg.selected:before {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px white solid;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.gap10 {
  grid-gap: 10px;
  gap: 10px;
}

.slideTemplate_list {
    margin-top: 10px;
    text-transform: capitalize;
}
.slideTemplate_list .img {
    width: 284px;
}
.slideTemplate_list img {
    border: 2px solid #fff;
    display: block;
    cursor: pointer;
    /* width: 284px; */
    /* height: 160px; */
    /* min-height: 125px; */
    width: 216px;
    height: 121px;
    margin: 0 auto;
}

.slideTemplate_list div {
    width: 100%;
    display: block;
    text-align: center;
}

.slideTemplate_list img:hover {
    border: 2px solid #f59d08;
}

.slideTemplate_list label {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif !important;
    font-size: 14px;
}

.action-template-image svg {
    width: 71px;
    height: 40px;
}

.cc-side {
    background: #fff;
    border-right: 1px #e2e2e2 solid;
    width: 88px;
    transition: width 0.3s ease;
    z-index: 2;
}
.cc-side.active {
    width: 432px;
    /* min-width: 432px; */
}

.cc-smenu {
    background: #fff;
    border-right: 1px solid #e2e2e2;
    width: 88px;
    height: 100%;
    left: 0;
    top: 0;
}
.cc-smenu > div {
    width: 88px;
    height: 88px;

    font-size: 12px;
    font-style: normal;
    font-weight: 325;
    line-height: 100%; /* 12px */
    text-align: center;
    cursor: pointer;
    z-index: 1;
}
.cc-smenu > div > span {
    width: 36px;
    height: 36px;
}

/* PANEL */
.cc-spanel {
    width: 344px;
    max-height: 100%;
    right: 0;
    height: 100%;
    overflow: hidden;
}
.cc-spwrap {
    padding: 0 30px 30px;
    height: 100%;
}
.cc-spbtn {
    width: 10px;
    height: 66px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: -10px;
    z-index: 100;
    cursor: pointer;
}
.cc-spbb {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cc-spba {
    display: block;
    position: absolute;
    margin-top: 24px;
    right: 5px;
}
.cc-smbox {
    width: 88px;
    height: 84px;
    position: absolute;
    top: 0;
    border: 1px solid #fff;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    pointer-events: none;
    display: none;
    /* outline-color: red !important; */
    /* z-index: 1; */
    /* background: #e2e2e2; */
}
.cc-smbox.on {
    transition: top 0.5s ease;
    display: block;
    /* transition: display 1s ease; */
}
.cc-smbox.off {
    border: 0 solid transparent;
    transition: border-color 0.3 ease;
}

.cc-smenu > div > * {
    pointer-events: none;
}
/* .cc-smenu > div {
    outline: 1px solid #e2e2e2;
} */

.c-s-p-tab {
    height: 80px;
    padding: 20px 0;
    /* outline: 1px solid green; */
    flex-shrink: 0;
}
.c-s-p-tab > div {
    flex: 1 1;
    text-align: center;
    padding: 7px 0 0;
    /* outline: 1px solid red; */
}
.c-s-p-tab span {
    position: relative;
    font-size: 16px;
}
.c-s-p-tab > div.active span::after {
    content: ""; /* Required for the pseudo-element to render */
    position: absolute; /* Position absolutely to avoid affecting text flow */
    bottom: -15px; /* Adjust this value to control the spacing */
    left: 0; /* Start from the left of the container */
    width: 100%; /* Span the full width of the container */
    border-bottom: 4px solid #3082f9; /* Define the underline style */
}

/* SWITCH RADIO BUTTON */
.cc_switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
    /* display: none; */
    cursor: pointer;
}

/* Hide default HTML checkbox */
.cc_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The slider_pillow */
.slider_pillow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

label input[type='checkbox'] + span.slider_pillow:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border: 0 !important;
}

input:checked + .slider_pillow {
    background-color: #2bd313;
}

input:focus + .slider_pillow {
    box-shadow: 0 0 1px #2bd313;
}

input:checked + .slider_pillow:before {
    transform: translateX(17px);
}

/* Rounded slider_pillows */
.slider_pillow.round {
    border-radius: 23px;
}

.slider_pillow.round:before {
    border-radius: 50%;
}

.cc-action {
    background: #fff;
    border-bottom: 1px solid #e2e2e2;
    /* color: #1e3968; */
    height: 76px;
    z-index: 1;
}
.cc-action > div {
    height: 36px;
}

.cc-alist {
    list-style: none;
    padding: 0;
}

.cc-alist li {
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;
    padding-right: 20px;
    margin-left: 20px;
}
.cc-alist li:first-child {
    margin: 0;
}
.cc-alist li:last-child {
    border: 0;
}
.cc-alist span {
    display: flex;
    justify-self: center;
    align-items: center;
    margin-right: 10px;
}

#presentation_container .cc-aright button:hover {
    border: 1px solid transparent;
    background: none;
}
.cc-aright span {
    margin-right: 8px;
}
/**
* old action bar styles
* TODO:: need to check and test in older presentation types
*/
.localmen-template-list {
    display: block;
    height: 42px;
    min-height: 42px;
    max-height: 42px;
    width: 73px;
    min-width: 73px;
    max-width: 73px;
    border: 1px solid #e1e1e1;
    overflow: hidden;
}
.localmen-template-list.active {
    border-color: orange;
}
.localmen-template-list img {
    height: 40px;
    width: 71px;
    max-height: 40px;
    min-height: 40px;
    max-width: 71px;
    min-width: 71px;
    display: inherit;
    cursor: pointer;
    overflow: hidden;
}

.cc-footer {
    height: 135px;
    background: #fff;
    border-top: 1px solid #e2e2e2;
}
.cc-footer.collapse {
    height: 46px;
}
.cc-fstatus {
    height: 46px;
}

/* ITEM */
.cc-fitem {
    display: inline-flex;
    padding: 5px 5px 3px 0px;
    margin-right: 15px;
    align-items: flex-start;
    /* outline: 2px solid red; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    width: 171px;
    height: 110px;
    overflow: hidden;
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
}
.cc-fitem.active {
    border: 2px solid #3082f9;
}
.cc-fino {
    width: 20px;
    text-align: center;
}
.testThumb {
    width: 932px;
    height: 526px;
    transform: scale(0.156);
    transform-origin: 0 0;
}

/* HIDE SLIDE */
.slide-hidden {
    position: absolute;
    top: 5px;
    left: 20px;
    background: rgba(255, 255, 255, 0.7);
    width: 146px;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-hidden > img {
    width: 26px;
    height: 26px;
}

/* OVERLAY */
.cc-faction {
    background: red;
    width: inherit;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
}
.cc-faction.act button,
.cc-faction:hover button {
    display: block;
}
.cc-faction button {
    border: 0;
    background: transparent;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.cc-faction button svg:hover {
    fill: #ffffff;
}
.cc-faction button img {
    pointer-events: none;
}

/* COLLAPS BUTTON */
/* .cc-fbtn {
    width: 13px;
    height: 84px;
    height: 68px;
    position: absolute;
    top: 0;
    top: -40px;
    left: 50%;
    transform: rotate(-90deg);
    cursor: pointer;
} */
.cc-fbtn.close .cc-fba {
    transform: rotate(180deg);
    top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
}
.cc-fba {
    position: absolute;
    /* left: -5px;
    top: 24px; */
    left: 50%;
    top: -10px;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    pointer-events: none;
}
.cc-fbb {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: rotate(90deg); */
    width: 84px;
    height: 14px;
    top: -13px;

    left: 50%;
    margin-left: -42px;
}

/* SCROLL */
.horizontal-scrollable-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 200px; /* Set the desired height */
}

.horizontal-scrollable-list > * {
    flex: 0 0 auto;
}

.c-f-menu {
    width: 200px;
    height: 118px;
    background: #fff;
    position: fixed;
    top: 0;
    padding: 15px 15px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    z-index: 1;
}
.c-f-menu > div {
    margin-bottom: 20px;
    font-size: 14px;
    height: 16px;
    display: flex;
    cursor: pointer;
}
.c-f-menu > div > span svg {
    margin-right: 10px;
}

div.pt20 {
    padding: 20px 0 0;
}

div.pt25 {
    padding: 25px 0 0;
}

#app_wrapper {
    height: 100%;
    min-height: 625px;
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif;
    /* max-width: 1134px; */
    min-width: 980px;
    margin: 0 auto;
}

#app_wrapper.other-page button:hover {
    border: 0;
}

#app_wrapper .title {
    /* outline: 1px dashed green; */
    flex: 0 0 100px;
}

#app_wrapper .draw {
    /* outline: 1px dashed orange; */
    flex: 0 0;
    height: 486px;
    width: 1134px;
    /* height: 550px; */
    height: 605px;
    box-shadow: 0px 6px 15px 0 rgb(82 82 82 / 28%);
}

/* #app_wrapper>div>aside {
  outline: 1px dashed blue;
} */

.draw {
    /* height: calc(100% - 50px); */
    width: 100%;
    /* width: 1136px; */
    background: #fff;
    margin: 0 auto;
}

.draw aside {
    flex: 0 0 182px;
    max-width: 182px;
}

#fontFamily .cc *,
#fontFamily p {
    font-family: inherit;
    margin: inherit;
}

.draw > div {
    flex: 1 1;
    width: 952px;
    padding: 12px;
    /* overflow-x: auto; */
    /* align-items: center; */
    height: 100%;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    /* padding-left: 2px; */
    position: relative;
    /* top: 66px; */
}
/* .main.board {
    padding-top: 68px;
    z-index: 1;
    box-shadow: 0px 6px 15px #00000048;
} */

/* .draw section {
  min-width: 1200px;
} */

.unauthorized {
    height: 100%;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderWrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1020;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
}

.loader {
    border: 4px solid #fff;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.6s linear infinite;
    position: absolute;
    z-index: 1000;
    /* box-shadow: 0 0 10px #000; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#testID .backgroundimage:hover::after {
    border: 0;
}

.breakAll {
    word-break: break-all;
}

/*@pfc-black: #231f20;*/
.icon {
    font-family: 'pfc-font-icon' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.pfc-instagram-logo:before {
    content: '\e900';
    text-transform: none !important;
}
.pfc-footer-usp:before {
    content: '\61';
    text-transform: none !important;
}
.pfc-address-book:before {
    content: '\62';
    text-transform: none !important;
}
.pfc-arrow-clock:before {
    content: '\63';
    text-transform: none !important;
}
.pfc-arrow-down:before {
    content: '\64';
    text-transform: none !important;
}
.pfc-arrow-left:before {
    content: '\65';
    text-transform: none !important;
}
.pfc-arrow-right:before {
    content: '\66';
    text-transform: none !important;
}
.pfc-arrow-up:before {
    content: '\67';
    text-transform: none !important;
}
.pfc-breadcrumb-home:before {
    content: '\68';
    text-transform: none !important;
}
.pfc-calendar:before {
    content: '\69';
    text-transform: none !important;
}
.pfc-checkmark:before {
    content: '\6a';
    text-transform: none !important;
}
.pfc-close:before {
    content: '\6b';
    text-transform: none !important;
}
.pfc-delete:before {
    content: '\6c';
    text-transform: none !important;
}
.pfc-delivry-solid:before {
    content: '\6d';
    text-transform: none !important;
}
.pfc-download:before {
    content: '\6e';
    text-transform: none !important;
}
.pfc-reposition:before {
    content: '\6f';
    text-transform: none !important;
}
.pfc-refresh:before {
    content: '\70';
    text-transform: none !important;
}
.pfc-maximize:before {
    content: '\71';
    text-transform: none !important;
}
.pfc-addtext:before {
    content: '\72';
    text-transform: none !important;
}
.pfc-likendin-logo:before {
    content: '\73';
    text-transform: none !important;
}
.pfc-info-icon:before {
    content: '\74';
    text-transform: none !important;
}
.pfc-image-icon:before {
    content: '\75';
    text-transform: none !important;
}
.pfc-arrow-up-thing:before {
    content: '\76';
    text-transform: none !important;
}
.pfc-arrow-left-thing:before {
    content: '\77';
    text-transform: none !important;
}
.pfc-arrow-down-thing:before {
    content: '\78';
    text-transform: none !important;
}
.pfc-arrow-right-thing:before {
    content: '\79';
    text-transform: none !important;
}
.pfc-euro:before {
    content: '\7a';
    text-transform: none !important;
}
.pfc-edit:before {
    content: '\41';
    text-transform: none !important;
}
.pfc-rotate:before {
    content: '\42';
    text-transform: none !important;
}
.pfc-mail:before {
    content: '\43';
    text-transform: none !important;
}
.pfc-mainsearch-icon:before {
    content: '\44';
    text-transform: none !important;
}
.pfc-minus:before {
    content: '\45';
    text-transform: none !important;
}
.pfc-no-decoration:before {
    content: '\46';
    text-transform: none !important;
}
.pfc-notes:before {
    content: '\47';
    text-transform: none !important;
}
.pfc-magnifying-glass:before {
    content: '\48';
    text-transform: none !important;
}
.pfc-phone:before {
    content: '\49';
    text-transform: none !important;
}
.pfc-pinpoint-line:before {
    content: '\4a';
    text-transform: none !important;
}
.pfc-pinpoint-solid:before {
    content: '\4b';
    text-transform: none !important;
}
.pfc-plus:before {
    content: '\4c';
    text-transform: none !important;
}
.pfc-shipping-icon:before {
    content: '\4d';
    text-transform: none !important;
}
.pfc-shopping-cart:before {
    content: '\4e';
    text-transform: none !important;
}
.pfc-youtube-logo:before {
    content: '\4f';
    text-transform: none !important;
}
.pfc-warning:before {
    content: '\50';
    text-transform: none !important;
}
.pfc-vimeo-logo:before {
    content: '\51';
    text-transform: none !important;
}
.pfc-view:before {
    content: '\52';
    text-transform: none !important;
}
.pfc-reports:before {
    content: '\53';
    text-transform: none !important;
}
.pfc-user-icon:before {
    content: '\54';
    text-transform: none !important;
}
.pfc-upload-visual:before {
    content: '\55';
    text-transform: none !important;
}
.pfc-twitter-logo:before {
    content: '\56';
    text-transform: none !important;
}
.pfc-print:before {
    content: '\57';
    text-transform: none !important;
}
.pfc-express:before {
    content: '\58';
    text-transform: none !important;
}
.pfc-shoppigcartplus:before {
    content: '\59';
    text-transform: none !important;
}
.pfc-trust:before {
    content: '\5a';
    text-transform: none !important;
}
.pfc-book:before {
    content: '\30';
    text-transform: none !important;
}
.pfc-zoom:before {
    content: '\31';
    text-transform: none !important;
}
.pfc-cog:before {
    content: '\32';
    text-transform: none !important;
}
.pfc-embroidery:before {
    content: '\33';
    text-transform: none !important;
}
.pfc-get-quote:before {
    content: '\34';
    text-transform: none !important;
}
.pfc-hxd:before {
    content: '\35';
    text-transform: none !important;
}
.pfc-padprint:before {
    content: '\36';
    text-transform: none !important;
}
.pfc-resize:before {
    content: '\37';
    text-transform: none !important;
}
.pfc-resize-horizontal:before {
    content: '\38';
    text-transform: none !important;
}
.pfc-cross-remove:before {
    content: '\39';
    text-transform: none !important;
}
.pfc-circle:before {
    content: '\21';
}
.pfc-diameter:before {
    content: '\23';
}
.pfc-check-circle:before {
    content: '\22';
}
.pfc-paint-brush:before {
    content: '\24';
}
.pfc-calendar-empty:before {
    content: '\25';
}
.pfc-factory:before {
    content: '\26';
}
.pfc-check-outline:before {
    content: '\27';
}
.pfc-bookmarks:before {
    content: '\2a';
}
.pfc-power:before {
    content: '\3a';
}
.pfc-transactoions:before {
    content: '\2e';
}
.pfc-user:before {
    content: '\2f';
}
.pfc-cart:before {
    content: '\28';
}
.pfc-new:before {
    content: '\29';
}
.pfc-sale:before {
    content: '\2b';
}
.pfc-information:before {
    content: '\2c';
}
.pfc-presentation:before {
    content: '\e901';
    text-transform: none !important;
}
.pfc-gear:before {
    content: '\e902';
    text-transform: none !important;
}
.pfc-overview:before {
    content: '\e903';
    text-transform: none !important;
}
.pfc-profile-settings:before {
    content: '\e905';
    text-transform: none !important;
}
.pfc-dashboard:before {
    content: '\e90c';
    text-transform: none !important;
}
.pfc-invoice:before {
    content: '\e90b';
    text-transform: none !important;
}
.pfc-mail-new:before {
    content: '\e90a';
    text-transform: none !important;
}
.pfc-order:before {
    content: '\e909';
    text-transform: none !important;
}
.pfc-pass:before {
    content: '\e904';
    text-transform: none !important;
}
.pfc-user-manage:before {
    content: '\e908';
    text-transform: none !important;
}
.pfc-config:before {
    content: '\e90d';
    text-transform: none !important;
}
.pfc-collection-new:before {
    content: '\e906';
    text-transform: none !important;
}
.pfc-save:before {
    content: '\e907';
    text-transform: none !important;
}
.pfc-collection:before {
    content: '\e90e';
    text-transform: none !important;
}
.pfc-category:before {
    content: '\e90f';
    text-transform: none !important;
}
.pfc-bestseller:before {
    content: '\e910';
    text-transform: none !important;
}
.pfc-chart:before {
    content: '\e911';
    text-transform: none !important;
}
.pfc-hidden:before {
    content: '\e914';
    text-transform: none !important;
}
.pfc-bag:before {
    content: '\e912';
    text-transform: none !important;
}
.pfc-sample:before {
    content: '\e913';
    text-transform: none !important;
}
.pfc-new-nav:before {
    content: '\e915';
    text-transform: none !important;
}
.pfc-sale-nav:before {
    content: '\e916';
    text-transform: none !important;
}
.pfc-bestsellers:before {
    content: '\e917';
    text-transform: none !important;
}
.pfc-green-points:before {
    content: '\e91b';
    text-transform: none !important;
}
.pfc-delete-new:before {
    content: '\e91a';
    text-transform: none !important;
}
.pfc-arrow-up-full:before {
    content: '\e918';
    text-transform: none !important;
}
.pfc-upload-alt:before {
    content: '\e919';
    text-transform: none !important;
}
.pfc-brand:before {
    content: '\e91d';
    text-transform: none !important;
}
.pfc-product:before {
    content: '\e91c';
    text-transform: none !important;
}
.pfc-brand:before {
    content: '\e91d';
    text-transform: none !important;
}
.pfc-product:before {
    content: '\e91c';
    text-transform: none !important;
}
.pfc-download-new:before {
    content: '\e91e';
    text-transform: none !important;
}
.pfc-address-book-cart:before {
    content: '\e91f';
    text-transform: none !important;
}
.pfc-dyson-video:before {
    content: '\e920';
    text-transform: none !important;
}
.pfc-dyson-catalogue:before {
    content: '\e921';
    text-transform: none !important;
}
.pfc-dyson-cart:before {
    content: '\e922';
    text-transform: none !important;
}
.pfc-dyson-image-bold:before {
    content: '\e923';
    text-transform: none !important;
}
.pfc-dyson-arrow-left:before {
    content: '\e924';
    text-transform: none !important;
}
.pfc-dyson-warning:before {
    content: '\e925';
    text-transform: none !important;
}
.pfc-reset:before {
    content: '\e926';
    text-transform: none !important;
}
.pf-zoom-in:before {
    content: '\e927';
    text-transform: none !important;
}
.pfc-checkmark-line:before {
    content: '\e928';
    text-transform: none !important;
}
.pfc-dyson-input-clear:before {
    content: '\e929';
    text-transform: none !important;
}
.pfc-dyson-check:before {
    content: '\e92a';
    text-transform: none !important;
}
body {
    font-size: 16px;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}
.cms-home {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #000000;
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-weight: 500;
    line-height: 1.2;
}
h1,
.h1 {
    font-size: 39.008px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h2,
.h2 {
    font-size: 34px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h3,
.h3 {
    font-size: 28.992px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h4,
.h4 {
    font-size: 24.992px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
h5,
.h5 {
    font-size: 22px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
h6,
.h6 {
    font-size: 19.008px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
p {
    font-size: 16px;
    color: #000000;
    line-height: 1.5;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
p.medium {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
}
p.small {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 12px;
    line-height: 1.5;
}
ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 30px;
}
ul li {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    margin-bottom: 15px;
    /*    &::before {
      content: "•";
      display: inline-block;
      margin-left: 13px;
      margin-right: 13px;
      }*/
}
ul li:last-child {
    margin-bottom: 0;
}
ul.small li {
    font-size: 12px;
}
ul.medium li {
    font-size: 14px;
}
ul.unstyled {
    list-style-type: none;
}
ol {
    list-style-type: decimal-leading-zero;
    counter-reset: li;
    list-style-position: outside;
    padding-left: 30px;
}
ol li {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    position: relative;
    margin-bottom: 15px;
    /*  &:before {
      counter-increment: li;
      content          : counter(li, decimal-leading-zero);

      margin-right : 0.25em;
      font-family  : @font-aleo_regular;
      padding-right: 10px;
    }*/
}
ol li:last-child {
    margin-bottom: 0;
}
ol.small li {
    font-size: 12px;
}
ol.medium li {
    font-size: 14px;
}
ol.unstyled {
    counter-reset: unset;
    list-style-position: outside;
    list-style: none;
    /*  li
    {
      &:before
      {
        display: none ;
      }
    }*/
}
.text-regular_default {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-regular_medium {
    font-size: 14px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-regular_small {
    font-size: 12px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_default {
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_medium {
    font-size: 14px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_small {
    font-size: 12px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_default {
    font-size: 16px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_medium {
    font-size: 14px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_small {
    font-size: 12px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
q,
blockquote,
.quote {
    font-family: 'Aleo-Bold', Verdana, Arial, sans-serif;
    font-size: 34px;
    color: #778186;
    line-height: 1.174;
}
a {
    font-size: 18px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #00457c;
    text-decoration: none;
    cursor: pointer;
}
.bluefoot-wrapper a:not(.btn) {
    text-decoration: underline;
}
a:hover {
    color: #00457c;
}
.new-tag {
    background: #00457c;
    font-size: 16px;
    color: #ffffff;
    padding: 5px 15px;
    text-transform: uppercase;
}
.sale-tag {
    background: #ec5f6c;
    font-size: 16px;
    color: #ffffff;
    padding: 5px 15px;
    text-transform: uppercase;
}
ul.list-group {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}
ul.list-group li {
    font-size: 18px;
    color: #000000;
}
ul.list-group li:before {
    display: inline-block;
    content: '';
    width: 9px;
    height: 9px;
    background: #00457c;
    margin-right: 15px;
}
ol.list-group {
    margin-left: 0;
    padding-left: 0;
    list-style-position: outside;
}
ol.list-group li {
    font-size: 18px;
    color: #000000;
}
.page-title {
    margin-top: 0;
}
.pf-dropdown-option a {
    font-size: 16px;
}
.footer-links li {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.text-line-height {
    line-height: 1.3;
}
h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced,
.h1.spaced,
.h2.spaced,
.h3.spaced,
.h4.spaced,
.h5.spaced,
.h6.spaced,
p.spaced,
ul.spaced,
ol.spaced,
q.spaced,
blockquote.spaced,
.quote.spaced {
    margin-bottom: 20px;
}
p.spaced + p {
    margin-bottom: 20px;
}
p.spaced + h1,
p.spaced + h2,
p.spaced + h3,
p.spaced + h4,
p.spaced + h5,
p.spaced + h6 {
    margin-top: 40px;
}
p.spaced + ul,
p.spaced + ol,
p.spaced q,
p.spaced blockquote,
p.spaced .quote {
    margin-top: 30px;
}
ul.spaced + p {
    margin-top: 30px;
}
ol.spaced + p {
    margin-top: 30px;
}
q.spaced + p,
blockquote.spaced + p,
.quote.spaced + p {
    margin-top: 30px;
}
.action {
    outline: none !important;
    box-shadow: none !important;
    transition: 0.25s ease-in-out;
    font-weight: 350;
}
.action:hover,
.action:focus,
.action:active {
    outline: none !important;
    box-shadow: none !important;
}
.action,
.btn-default {
    line-height: 1;
    height: auto;
    width: auto;
    border-radius: 150px;
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    min-width: 105px;
    display: inline-flex;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    border: none;
    padding: 14px 25px;
    font-weight: normal;
    cursor: pointer;
    /*&:hover {
          opacity        : 0.75;
          text-decoration: none;
        }*/
}
.action.primary,
.btn-default.primary,
.action.secondary,
.btn-default.secondary,
.action.tertiary,
.btn-default.tertiary {
    line-height: 1;
    height: auto;
    width: auto;
    border-radius: 150px;
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    min-width: 105px;
    padding: 14px 25px;
    font-weight: normal;
}
.action:disabled,
.btn-default:disabled {
    opacity: 0.5;
    cursor: default;
}
.action:disabled:hover,
.btn-default:disabled:hover {
    opacity: 0.5;
    cursor: default;
}
.action.btn-icon,
.btn-default.btn-icon {
    padding: 14px 25px 14px 20px;
}
.action.btn-icon i,
.btn-default.btn-icon i {
    margin-right: 8px;
}
.action.btn-medium,
.btn-default.btn-medium {
    min-width: 92px;
    height: auto;
    padding: 10px 22px;
    font-size: 14px;
}
.action.btn-medium.btn-icon,
.btn-default.btn-medium.btn-icon {
    padding: 10px 22px 10px 17px;
}
.action.btn-medium.btn-icon i,
.btn-default.btn-medium.btn-icon i {
    margin-right: 8px;
    float: left;
}
.action.btn-small,
.btn-default.btn-small {
    height: auto;
    min-width: 80px;
    padding: 6px 19px;
    font-size: 12px;
}
.action.btn-small.btn-icon,
.btn-default.btn-small.btn-icon {
    padding: 6px 19px 6px 14px;
}
.action.btn-small.btn-icon i,
.btn-default.btn-small.btn-icon i {
    margin-right: 8px;
    float: left;
}
.action-icon i,
.btn-default-icon i {
    margin-right: 12px;
    float: left;
}
.action.primary,
.btn-default.primary {
    background: #1e3968;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}
.action.primary:hover,
.btn-default.primary:hover {
    background-size: 100% 100%;
    border-color: #4b6186;
}
.action.primary:active,
.btn-default.primary:active,
.action.primary:focus,
.btn-default.primary:focus {
    background: #1e3968;
}
.action.primary:disabled,
.btn-default.primary:disabled {
    background: #1e3968;
}
.action.secondary,
.btn-default.secondary,
.action .call-to-action,
.btn-default .call-to-action {
    background: #f59d08;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    color: #ffffff;
}
.action.secondary:hover,
.btn-default.secondary:hover,
.action .call-to-action:hover,
.btn-default .call-to-action:hover {
    background-size: 100% 100%;
    border-color: #f7b139;
}
.action.secondary:active,
.btn-default.secondary:active,
.action .call-to-action:active,
.btn-default .call-to-action:active,
.action.secondary:focus,
.btn-default.secondary:focus,
.action .call-to-action:focus,
.btn-default .call-to-action:focus {
    background: #f59d08;
}
.action.secondary:disabled,
.btn-default.secondary:disabled,
.action .call-to-action:disabled,
.btn-default .call-to-action:disabled {
    background: #f59d08;
    border: 1px #f59d08 solid;
}
.action.full,
.btn-default.full {
    width: 100%;
}
.action.width,
.btn-default.width {
    min-width: 105px !important;
}
.action.link,
.btn-default.link {
    background-color: transparent;
    width: auto;
    margin: 0;
    border: 1px solid transparent;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #1e3968;
}
.action.link:before,
.btn-default.link:before {
    color: #1e3968 !important;
}
.action.tertiary,
.btn-default.tertiary {
    background: transparent;
    background-image: linear-gradient(#f1f4f5, #f1f4f5);
    background-size: 0 100%;
    background-repeat: no-repeat;
    border: 1px transparent solid;
    color: #1e3968;
}
.action.tertiary:hover,
.btn-default.tertiary:hover {
    background-size: 100% 100%;
    border-color: #f1f4f5;
}
.action.tertiary:active,
.btn-default.tertiary:active,
.action.tertiary:focus,
.btn-default.tertiary:focus {
    background: #f1f4f5;
    border: 1px #f1f4f5 solid;
}
.action.tertiary:disabled,
.btn-default.tertiary:disabled {
    background: transparent;
    border: 1px transparent solid;
}
.action.tertiary:disabled:hover,
.btn-default.tertiary:disabled:hover {
    background: transparent;
    border: 1px transparent solid;
}
.action.ghost,
.btn-default.ghost {
    background: #ffffff;
    border: 1px #1e3968 solid;
    color: #1e3968;
    padding: 13px 25px;
}
.action.ghost:hover,
.btn-default.ghost:hover,
.action.ghost:active,
.btn-default.ghost:active,
.action.ghost:focus,
.btn-default.ghost:focus {
    background: #ffffff;
    border: 1px #1e3968 solid;
}
.action.ghost:disabled,
.btn-default.ghost:disabled {
    background: #ffffff;
    border: 1px #1e3968 solid;
}
.action.ghost.btn-icon,
.btn-default.ghost.btn-icon {
    padding: 13px 25px 13px 20px;
}
.action.ghost.btn-medium,
.btn-default.ghost.btn-medium {
    padding: 9px 22px;
}
.action.ghost.btn-medium.btn-icon,
.btn-default.ghost.btn-medium.btn-icon {
    padding: 9px 22px 9px 17px;
}
.action.ghost.btn-small,
.btn-default.ghost.btn-small {
    padding: 5px 19px;
}
.action.ghost.btn-small.btn-icon,
.btn-default.ghost.btn-small.btn-icon {
    padding: 5px 19px 5px 14px;
}
.action:hover,
.btn-default:hover {
    background-size: 100% 100%;
    border-color: #4b6186;
}
.action[class*='pfc-'],
.action[class*='vision-'] {
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)) !important;
    background-size: 0 100% !important;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat !important;
    color: #ffffff;
}
.action[class*='pfc-']:hover,
.action[class*='vision-']:hover {
    background-size: 100% 100% !important;
}
.action[class*='pfc-']:disabled,
.action[class*='vision-']:disabled {
    opacity: 0.5;
    cursor: default;
}
.action[class*='pfc-']:disabled:hover,
.action[class*='vision-']:disabled:hover {
    opacity: 0.5;
    cursor: default;
    background-size: 0 0 !important;
}
.action.pfc-dark-blue {
    background: #1e3968;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
}
.action.pfc-dark-blue_100 {
    background: #ccdae5;
}
.action.pfc-dark-blue_200 {
    background: #a5b0c3;
}
.action.pfc-dark-blue_300 {
    background: #7888a4;
}
.action.pfc-dark-blue_400 {
    background: #4b6186;
}
.action.pfc-dark-blue_500 {
    background: #1e3968;
}
.action.pfc-dark-blue_600 {
    background: #182e53;
}
.action.pfc-dark-blue_700 {
    background: #12223e;
}
.action.pfc-dark-blue_800 {
    background: #0c172a;
}
.action.pfc-dark-blue_900 {
    background: #060b15;
}
.action.pfc-dark-blue:hover {
    background-size: 100% 100%;
}
.action.pfc-dark-red {
    background: #d32f2f;
}
.action.pfc-bright-blue {
    background: #3082f9;
}
.action.pfc-bright-blue_100 {
    background: #eaf3fe;
}
.action.pfc-bright-blue_200 {
    background: #c1dafd;
}
.action.pfc-bright-blue_300 {
    background: #98c1fc;
}
.action.pfc-bright-blue_400 {
    background: #6ea8fb;
}
.action.pfc-bright-blue_500 {
    background: #3082f9;
}
.action.pfc-bright-blue_600 {
    background: #2668c7;
}
.action.pfc-bright-blue_700 {
    background: #1d4e95;
}
.action.pfc-bright-blue_800 {
    background: #133464;
}
.action.pfc-bright-blue_900 {
    background: #0e274b;
}
.action.pfc-bright-green {
    background: #2bd313;
}
.action.pfc-aqua {
    background: #15989d;
}
.action.pfc-green {
    background: #759764;
}
.action.pfc-fresh-green {
    background: #4a9d7d;
}
.action.pfc-light-green {
    background: #aad0c0;
}
.action.pfc-red {
    background: #ec5f6c;
}
.action.pfc-grey_100 {
    background: #f6f6f6;
}
.action.pfc-grey_200 {
    background: #eeeeee;
}
.action.pfc-grey_300 {
    background: #e2e2e2;
}
.action.pfc-grey_400 {
    background: #cccccc;
}
.action.pfc-grey_500 {
    background: #999999;
}
.action.pfc-grey_600 {
    background: #666666;
}
.action.pfc-grey_700 {
    background: #333333;
}
.action.pfc-grey_800 {
    background: #1a1a1a;
}
.action.pfc-yellow-grey_100 {
    background: #f6f3f0;
}
.action.pfc-yellow-grey_200 {
    background: #f1ede9;
}
.action.pfc-yellow-grey_300 {
    background: #e0d7cd;
}
.action.pfc-yellow-grey_400 {
    background: #b3aca4;
}
.action.pfc-blue-grey_100 {
    background: #f1f4f5;
}
.action.pfc-blue-grey_200 {
    background: #e5eaec;
}
.action.pfc-blue-grey_300 {
    background: #9da9b1;
}
.action.pfc-blue-grey_400 {
    background: #778186;
}
.action.pfc-black {
    background: #000000;
}
.action.pfc-white {
    background: #ffffff;
    color: #000000;
}
.action.pfc-orange {
    background: #f59d08;
}
.action.pfc-yellow {
    background: #ffd04d;
}
.action.pfc-light-yellow {
    background: #fdfbcb;
}
.action.vision-on-people {
    background: #367699;
}
.action.vision-on-product {
    background: #79bfca;
}
.action.vision-on-process {
    background: #56a591;
}
.action.green-points {
    background: #8eb53f;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)) !important;
    background-size: 0 100% !important;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat !important;
    color: #ffffff;
}
.action.green-points:hover {
    background-size: 100% 100% !important;
}
.action.green-points:disabled {
    opacity: 0.5;
    cursor: default;
}
.action.green-points:disabled:hover {
    opacity: 0.5;
    cursor: default;
}
.action.pastel-green {
    background: #cadebc;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    color: #ffffff;
}
.action.pastel-green:hover {
    background-size: 100% 100%;
}
.action.pastel-green:disabled {
    opacity: 0.5;
    cursor: default;
}
.action.pastel-green:disabled:hover {
    opacity: 0.5;
    cursor: default;
    background-size: 0 0 !important;
}
.checkbox {
    display: flex;
    justify-content: left;
}
.checkbox input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: -1;
}
.checkbox input[type='checkbox']:checked + label::after {
    content: '';
    border-color: #1e3968;
}
.checkbox input[type='checkbox']:checked + label::before {
    border-color: #1e3968;
}
.checkbox input[type='checkbox']:disabled + label {
    opacity: 0.4;
    cursor: not-allowed;
}
.checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #000000;
    font-size: 16px;
}
.checkbox label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
}
.checkbox label::after {
    position: absolute;
    left: 0;
    top: 0;
    content: none;
    height: 5px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    top: 4px;
    border-color: #1e3968;
}
.checkbox label span {
    font-size: 12px;
    color: #d32f2f;
    display: block;
    line-height: 1;
    padding-top: 5px;
}
.checkbox.error input[type='checkbox']:checked + label::before {
    border-color: #d32f2f;
}
.checkbox.error label::before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #d32f2f;
}
.checkbox.label_right label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
}
.checkbox.label_right label::before {
    position: absolute;
    left: 0;
    top: 0;
}
.checkbox.label_right label::after {
    position: absolute;
    left: 0;
    top: 0;
}
.checkbox.label_left label {
    padding-left: 0;
    padding-right: 30px;
}
.checkbox.label_left label::before {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.checkbox.label_left label::after {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    right: 5px;
    top: 5px;
}
.checkbox.label_left.error {
    text-align: right;
}
.checkbox.label_left.error label span {
    text-align: right;
}
.radio {
    display: flex;
    justify-content: left;
}
.radio input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    opacity: 0 !important;
    z-index: -1;
}
.radio input[type='radio']:checked + label::after {
    content: '';
    border-color: #1e3968;
}
.radio input[type='radio']:checked + label::before {
    border-color: #1e3968;
    color: #1e3968;
    padding: 0;
    margin: 0;
    vertical-align: inherit;
    margin-right: 10px;
    background-color: #1e3968;
    border: 5px #fff solid;
    border-color: white !important;
    box-shadow: 0 0 0 1px #1e3968;
    outline: none;
    transition: 0.1s;
    /*height: 10px;
          width: 10px;*/
    content: '';
}
.radio input[type='radio']:disabled + label {
    opacity: 0.4;
    cursor: not-allowed;
}
.radio label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #000000;
    font-size: 16px;
    /*  display: flex;
    align-items: center;*/
}
.radio label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    /*   border: 1px solid @pfc-grey_400;
*/
    box-shadow: 0 0 0 1px #cccccc;
    outline: none;
    transition: 0.1s;
    border: none;
}
.radio label span {
    font-size: 12px;
    color: #d32f2f;
    display: block;
    padding-top: 5px;
    line-height: 1;
}
.radio.error input[type='radio']:checked + label::before {
    border-color: #d32f2f;
    box-shadow: 0 0 0 1px #d32f2f;
    margin-right: 0;
}
.radio.error label::before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: none;
    box-shadow: 0 0 0 1px #d32f2f;
}
.radio.label_right label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
}
.radio.label_right label::before {
    position: absolute;
    left: 0;
    top: 0;
}
.radio.label_right label::after {
    position: absolute;
    left: 0;
    top: 0;
}
.radio.label_left label {
    padding-left: 0;
    padding-right: 30px;
}
.radio.label_left label::before {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.radio.label_left label::after {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    right: 5px;
    top: 5px;
}
.radio.label_left.error {
    text-align: right;
}
.radio.label_left.error label span {
    text-align: right;
    padding-top: 5px;
}
.radio.label_left input[type='radio']:checked + label::before {
    margin-right: 0;
}
.pfc-dark-blue {
    color: #1e3968;
}
.pfc-dark-blue-bg {
    background-color: #1e3968;
}
.pfc-dark-blue_100 {
    color: #ccdae5;
}
.pfc-dark-blue_100-bg {
    background-color: #ccdae5;
}
.pfc-dark-blue_200 {
    color: #a5b0c3;
}
.pfc-dark-blue_200-bg {
    background-color: #a5b0c3;
}
.pfc-dark-blue_300 {
    color: #7888a4;
}
.pfc-dark-blue_300-bg {
    background-color: #7888a4;
}
.pfc-dark-blue_400 {
    color: #4b6186;
}
.pfc-dark-blue_400-bg {
    background-color: #4b6186;
}
.pfc-dark-blue_500 {
    color: #1e3968;
}
.pfc-dark-blue_500-bg {
    background-color: #1e3968;
}
.pfc-dark-blue_600 {
    color: #182e53;
}
.pfc-dark-blue_600-bg {
    background-color: #182e53;
}
.pfc-dark-blue_700 {
    color: #12223e;
}
.pfc-dark-blue_700-bg {
    background-color: #12223e;
}
.pfc-dark-blue_800 {
    color: #0c172a;
}
.pfc-dark-blue_800-bg {
    background-color: #0c172a;
}
.pfc-dark-blue_900 {
    color: #060b15;
}
.pfc-dark-blue_900-bg {
    background-color: #060b15;
}
.pfc-dark-red {
    color: #d32f2f;
}
.pfc-dark-red-bg {
    background-color: #d32f2f;
}
.pfc-bright-blue {
    color: #3082f9;
}
.pfc-bright-blue-bg {
    background-color: #3082f9;
}
.pfc-bright-blue_100 {
    color: #eaf3fe;
}
.pfc-bright-blue_100-bg {
    background-color: #eaf3fe;
}
.pfc-bright-blue_200 {
    color: #c1dafd;
}
.pfc-bright-blue_200-bg {
    background-color: #c1dafd;
}
.pfc-bright-blue_300 {
    color: #98c1fc;
}
.pfc-bright-blue_300-bg {
    background-color: #98c1fc;
}
.pfc-bright-blue_400 {
    color: #6ea8fb;
}
.pfc-bright-blue_400-bg {
    background-color: #6ea8fb;
}
.pfc-bright-blue_500 {
    color: #3082f9;
}
.pfc-bright-blue_500-bg {
    background-color: #3082f9;
}
.pfc-bright-blue_600 {
    color: #2668c7;
}
.pfc-bright-blue_600-bg {
    background-color: #2668c7;
}
.pfc-bright-blue_700 {
    color: #1d4e95;
}
.pfc-bright-blue_700-bg {
    background-color: #1d4e95;
}
.pfc-bright-blue_800 {
    color: #133464;
}
.pfc-bright-blue_800-bg {
    background-color: #133464;
}
.pfc-bright-blue_900 {
    color: #0e274b;
}
.pfc-bright-blue_900-bg {
    background-color: #0e274b;
}
.pfc-bright-green {
    color: #2bd313;
}
.pfc-bright-green-bg {
    background-color: #2bd313;
}
.pfc-aqua {
    color: #15989d;
}
.pfc-aqua-bg {
    background-color: #15989d;
}
.pfc-green {
    color: #759764;
}
.pfc-green-bg {
    background-color: #759764;
}
.pfc-fresh-green {
    color: #4a9d7d;
}
.pfc-fresh-green-bg {
    background-color: #4a9d7d;
}
.pfc-light-green {
    color: #aad0c0;
}
.pfc-light-green-bg {
    background-color: #aad0c0;
}
.pfc-red {
    color: #ec5f6c;
}
.pfc-red-bg {
    background-color: #ec5f6c;
}
.pfc-grey_100 {
    color: #f6f6f6;
}
.pfc-grey_100-bg {
    background-color: #f6f6f6;
}
.pfc-grey_200 {
    color: #eeeeee;
}
.pfc-grey_200-bg {
    background-color: #eeeeee;
}
.pfc-grey_300 {
    color: #e2e2e2;
}
.pfc-grey_300-bg {
    background-color: #e2e2e2;
}
.pfc-grey_400 {
    color: #cccccc;
}
.pfc-grey_400-bg {
    background-color: #cccccc;
}
.pfc-grey_500 {
    color: #999999;
}
.pfc-grey_500-bg {
    background-color: #999999;
}
.pfc-grey_600 {
    color: #666666;
}
.pfc-grey_600-bg {
    background-color: #666666;
}
.pfc-grey_700 {
    color: #333333;
}
.pfc-grey_700-bg {
    background-color: #333333;
}
.pfc-grey_800 {
    color: #1a1a1a;
}
.pfc-grey_800-bg {
    background-color: #1a1a1a;
}
.pfc-yellow-grey_100 {
    color: #f6f3f0;
}
.pfc-yellow-grey_100-bg {
    background-color: #f6f3f0;
}
.pfc-yellow-grey_200 {
    color: #f1ede9;
}
.pfc-yellow-grey_200-bg {
    background-color: #f1ede9;
}
.pfc-yellow-grey_300 {
    color: #e0d7cd;
}
.pfc-yellow-grey_300-bg {
    background-color: #e0d7cd;
}
.pfc-yellow-grey_400 {
    color: #b3aca4;
}
.pfc-yellow-grey_400-bg {
    background-color: #b3aca4;
}
.pfc-blue-grey_100 {
    color: #f1f4f5;
}
.pfc-blue-grey_100-bg {
    background-color: #f1f4f5;
}
.pfc-blue-grey_200 {
    color: #e5eaec;
}
.pfc-blue-grey_200-bg {
    background-color: #e5eaec;
}
.pfc-blue-grey_300 {
    color: #9da9b1;
}
.pfc-blue-grey_300-bg {
    background-color: #9da9b1;
}
.pfc-blue-grey_400 {
    color: #778186;
}
.pfc-blue-grey_400-bg {
    background-color: #778186;
}
.pfc-black {
    color: #000000;
}
.pfc-black-bg {
    background-color: #000000;
}
.pfc-white {
    color: #ffffff;
}
.pfc-white-bg {
    background-color: #ffffff;
}
.pfc-orange {
    color: #f59d08;
}
.pfc-orange-bg {
    background-color: #f59d08;
}
.pfc-yellow {
    color: #ffd04d;
}
.pfc-yellow-bg {
    background-color: #ffd04d;
}
.pfc-light-yellow {
    color: #fdfbcb;
}
.pfc-light-yellow-bg {
    background-color: #fdfbcb;
}
.vision-on-people {
    color: #367699;
}
.vision-on-people-bg {
    background-color: #367699;
}
.vision-on-product {
    color: #79bfca;
}
.vision-on-product-bg {
    background-color: #79bfca;
}
.vision-on-process {
    color: #56a591;
}
.vision-on-process-bg {
    background-color: #56a591;
}
.green-points {
    color: #8eb53f;
}
.green-points-bg {
    background-color: #8eb53f;
}
.pastel-green {
    color: #cadebc;
}
.pastel-green-bg {
    background-color: #cadebc;
}
.pfc-spacer_5 {
    height: 5px;
}
.pfc-spacer_10 {
    height: 10px;
}
.pfc-spacer_15 {
    height: 15px;
}
.pfc-spacer_20 {
    height: 20px;
}
.pfc-spacer_25 {
    height: 25px;
}
.pfc-spacer_30 {
    height: 30px;
}
.pfc-spacer_35 {
    height: 35px;
}
.pfc-spacer_40 {
    height: 40px;
}
.pfc-spacer_60 {
    height: 60px;
}
.pfc-spacer_80 {
    height: 80px;
}
.m-t_5 {
    margin-top: 5px;
}
.m-t_10 {
    margin-top: 10px;
}
.m-t_15 {
    margin-top: 15px;
}
.m-t_20 {
    margin-top: 20px;
}
.m-t_25 {
    margin-top: 25px;
}
.m-t_30 {
    margin-top: 30px;
}
.m-t_35 {
    margin-top: 35px;
}
.m-t_40 {
    margin-top: 40px;
}
.m-t_60 {
    margin-top: 60px;
}
.m-t_80 {
    margin-top: 80px;
}
.m-b_5 {
    margin-bottom: 5px;
}
.m-b_10 {
    margin-bottom: 10px;
}
.m-b_15 {
    margin-bottom: 15px;
}
.m-b_20 {
    margin-bottom: 20px;
}
.m-b_25 {
    margin-bottom: 25px;
}
.m-b_30 {
    margin-bottom: 30px;
}
.m-b_35 {
    margin-bottom: 35px;
}
.m-b_40 {
    margin-bottom: 40px;
}
.m-b_60 {
    margin-bottom: 60px;
}
.m-b_80 {
    margin-bottom: 80px;
}
.m-l_5 {
    margin-left: 5px;
}
.m-l_10 {
    margin-left: 10px;
}
.m-l_15 {
    margin-left: 15px;
}
.m-l_20 {
    margin-left: 20px;
}
.m-l_25 {
    margin-left: 25px;
}
.m-l_30 {
    margin-left: 30px;
}
.m-l_35 {
    margin-left: 35px;
}
.m-l_40 {
    margin-left: 40px;
}
.m-l_60 {
    margin-left: 60px;
}
.m-l_80 {
    margin-left: 80px;
}
.m-r_5 {
    margin-right: 5px;
}
.m-r_10 {
    margin-right: 10px;
}
.m-r_15 {
    margin-right: 15px;
}
.m-r_20 {
    margin-right: 20px;
}
.m-r_25 {
    margin-right: 25px;
}
.m-r_30 {
    margin-right: 30px;
}
.m-r_35 {
    margin-right: 35px;
}
.m-r_40 {
    margin-right: 40px;
}
.m-r_60 {
    margin-right: 60px;
}
.m-r_80 {
    margin-right: 80px;
}
.p-t_5 {
    padding-top: 5px;
}
.p-t_10 {
    padding-top: 10px;
}
.p-t_15 {
    padding-top: 15px;
}
.p-t_20 {
    padding-top: 20px;
}
.p-t_25 {
    padding-top: 25px;
}
.p-t_30 {
    padding-top: 30px;
}
.p-t_35 {
    padding-top: 35px;
}
.p-t_40 {
    padding-top: 40px;
}
.p-t_60 {
    padding-top: 60px;
}
.p-t_80 {
    padding-top: 80px;
}
.p-b_5 {
    padding-bottom: 5px;
}
.p-b_10 {
    padding-bottom: 10px;
}
.p-b_15 {
    padding-bottom: 15px;
}
.p-b_20 {
    padding-bottom: 20px;
}
.p-b_25 {
    padding-bottom: 25px;
}
.p-b_30 {
    padding-bottom: 30px;
}
.p-b_35 {
    padding-bottom: 35px;
}
.p-b_40 {
    padding-bottom: 40px;
}
.p-b_60 {
    padding-bottom: 60px;
}
.p-b_80 {
    padding-bottom: 80px;
}
.p-l_5 {
    padding-left: 5px;
}
.p-l_10 {
    padding-left: 10px;
}
.p-l_15 {
    padding-left: 15px;
}
.p-l_20 {
    padding-left: 20px;
}
.p-l_25 {
    padding-left: 25px;
}
.p-l_30 {
    padding-left: 30px;
}
.p-l_35 {
    padding-left: 35px;
}
.p-l_40 {
    padding-left: 40px;
}
.p-l_60 {
    padding-left: 60px;
}
.p-l_80 {
    padding-left: 80px;
}
.p-r_5 {
    padding-right: 5px;
}
.p-r_10 {
    padding-right: 10px;
}
.p-r_15 {
    padding-right: 15px;
}
.p-r_20 {
    padding-right: 20px;
}
.p-r_25 {
    padding-right: 25px;
}
.p-r_30 {
    padding-right: 30px;
}
.p-r_35 {
    padding-right: 35px;
}
.p-r_40 {
    padding-right: 40px;
}
.p-r_60 {
    padding-right: 60px;
}
.p-r_80 {
    padding-right: 80px;
}
table.pfc-table {
    border-collapse: collapse;
    margin: 0;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    box-shadow: none;
    color: #000000;
    width: 100%;
}
table.pfc-table tr {
    border-left: 3px transparent solid;
}
table.pfc-table_has-border {
    border: 1px solid #e2e2e2;
}
table.pfc-table.has-shadow {
    outline: 1px solid #e2e2e2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
table.pfc-table thead tr {
    background-color: #ffffff;
    border-bottom: 1px solid #e2e2e2;
    text-align: left;
}
table.pfc-table tbody.parent-tr.active tr {
    border-left: 3px solid #00457c;
}
table.pfc-table tbody.child-tr tr {
    background: #fff;
    border-left: 3px solid #00457c;
}
table.pfc-table tbody.child-tr tr td {
    background: transparent;
}
table.pfc-table tbody.child-tr tr:first-child {
    background: linear-gradient(180deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tbody.child-tr tr:last-child {
    background: linear-gradient(0deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tbody:last-child tr:last-child td {
    border-bottom: none !important;
}
table.pfc-table th {
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    white-space: nowrap;
    font-weight: normal;
}
table.pfc-table th a {
    color: #1e3968;
}
table.pfc-table th a:hover {
    text-decoration: none;
}
table.pfc-table th i {
    font-size: 14px;
    color: #1e3968;
    font-family: 'pfc-font-icon';
    vertical-align: middle;
    margin-left: 10px;
}
table.pfc-table tr th,
table.pfc-table tr td {
    padding: 10px;
    margin: 10px;
    height: 61px;
    background: #ffffff;
    border-bottom: 1px solid #e2e2e2 !important;
    line-height: 1;
    vertical-align: middle;
}
table.pfc-table tr th:last-child a,
table.pfc-table tr td:last-child a {
    color: #000000;
    margin-left: 10px;
}
table.pfc-table tr th:last-child a:first-child,
table.pfc-table tr td:last-child a:first-child {
    margin-left: 0;
}
table.pfc-table tr th.align-left,
table.pfc-table tr td.align-left {
    text-align: left;
}
table.pfc-table tr th.align-right,
table.pfc-table tr td.align-right {
    text-align: right;
}
table.pfc-table tr td.has-icon i {
    font-size: 16px;
    color: #1e3968;
    font-family: 'pfc-font-icon';
    display: inline-block;
    vertical-align: top;
    transition: transform 0.2s ease 0s;
}
table.pfc-table tr td.has-icon i.active {
    transform: rotate(90deg);
}
table.pfc-table tr td img {
    display: block;
    max-height: 40px;
    width: auto;
}
table.pfc-table tr tbody tr {
    border-bottom: 1px solid #e2e2e2;
}
table.pfc-table tr tbody tr.hidden-tr + .hidden-tr {
    background: #fff;
}
table.pfc-table tr tbody tr.hidden-tr td {
    background: transparent;
}
table.pfc-table tr tbody tr.hidden-tr:first-child {
    background: linear-gradient(180deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tr tbody tr.hidden-tr:last-child {
    background: linear-gradient(0deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tr tbody tr:last-of-type {
    border-bottom: 1px solid #e2e2e2;
}
table.pfc-table tr tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
table.pfc-table.pfc-table_advance tr td.clipped-content {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}
.pfc-table-container {
    padding: 20px;
    background: #f6f6f6;
    border-radius: 8px;
}
.pfc-table-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.pfc-table-header .action {
    margin-left: 20px;
}
.pfc-table-header .form-control {
    font-size: 16px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.pfc-table-header .input-group {
    display: flex;
    position: relative;
}
.pfc-table-header .input-group.select::after {
    font-family: 'pfc-font-icon';
    font-size: 14px;
    content: '\64';
    color: #1e3968;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    transform: rotate(0deg);
    position: absolute;
    right: 12px;
    transition: all 0.25s;
    top: 0;
    top: 50%;
    transform: translateY(-50%);
}
.pfc-table-filter {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    display: flex;
    align-items: center;
}
.pfc-table-filter select.form-control {
    width: 70px;
}
.pfc-table-filter h3 {
    line-height: 1;
}
.pfc-table-filter .input-group {
    margin-right: 20px;
    margin-left: 20px;
}
.pfc-table-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.pfc-table-search {
    border-top: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pfc-table-search .input-group {
    flex: 1 1;
}
.pfc-table-search .input-group input {
    width: 100%;
}
.pfc-table-footer {
    display: flex;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-table-footer.align-right {
    justify-content: flex-end;
}
.pfc-table-footer.align-left {
    justify-content: flex-start;
}
td.test {
    width: 100px;
}
.pfc-link {
    display: inline-flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: underline !important;
    color: #1e3968;
}
.pfc-link.icon-link {
    text-decoration: none !important;
}
.pfc-link i {
    font-size: inherit;
    color: inherit;
    margin-right: 8px;
}
.pfc-link.block {
    display: flex;
}
.pfc-divider {
    width: 100%;
    height: 1px;
    display: block;
    background: #999999;
}
.pfc-divider_default {
    background: #999999;
}
.pfc-divider_light {
    background: #e2e2e2;
}
.pfc-divider_black {
    background: #000000;
}
.pfc-divider_vertical {
    width: 1px;
    height: 100%;
    min-height: 1px;
}
.pfc-shadow_ambient-10 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-15 {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-20 {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-25 {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-10 {
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-15 {
    box-shadow: 0px 15px 20px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-20 {
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-25 {
    box-shadow: 0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-10 {
    box-shadow:
        0px 0px 10px 0px rgba(0, 0, 0, 0.12),
        0px 10px 15px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-15 {
    box-shadow:
        0px 0px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 20px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-20 {
    box-shadow:
        0px 0px 20px 0px rgba(0, 0, 0, 0.12),
        0px 20px 25px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-25 {
    box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-range-slider {
    position: relative;
    width: 100%;
}
.pfc-range-slider .slider {
    position: relative;
    z-index: 1;
    height: 6px;
    margin: 0 15px;
}
.pfc-range-slider .slider > .track {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #e2e2e2;
}
.pfc-range-slider .slider > .range {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #2bd313;
}
.pfc-range-slider .slider > .thumb {
    position: absolute;
    z-index: 3;
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(30, 57, 104, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}
.pfc-range-slider .slider > .thumb.left {
    left: 0;
    transform: translate(-9px, -6px);
}
.pfc-range-slider .slider > .thumb.right {
    right: 25%;
    transform: translate(9px, -6px);
}
.pfc-range-slider .slider > .thumb.hover {
    box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.1);
}
.pfc-range-slider .slider > .thumb.active {
    box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.2);
}
.pfc-range-slider .multi-range-slider input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
}
.pfc-range-slider .multi-range-slider input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: #1e3968;
    -webkit-appearance: none;
}
.pfc-range-slider .standard-slider input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #ccc;
}
.pfc-range-slider .standard-slider input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    border: none;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.pfc-range-slider .standard-slider input[type='range']::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    border: none;
    -moz-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.pfc-range-slider .standard-slider .value {
    display: none;
}
.pfc-notification {
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    color: #ffffff;
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.pfc-notification i {
    padding: 6px;
    margin-right: 5px;
}
.pfc-notification_success {
    background-color: #2bd313;
}
.pfc-notification_information {
    background-color: #3082f9;
}
.pfc-notification_warning {
    background-color: #f59d08;
}
.pfc-notification_error {
    background-color: #d32f2f;
}
.pfc-notification_detail {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    background-color: #fdfbcb;
    color: #000000;
    padding: 15px 30px;
    position: relative;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 26px), calc(100% - 26px) 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 26px), calc(100% - 26px) 100%, 0 100%);
}
.pfc-notification_detail a {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #1e3968;
    margin-left: 5px;
}
.pfc-notification-bar {
    justify-content: center;
    padding: 10px 57px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    color: #000000;
    position: relative;
}
.pfc-notification-bar_important {
    background-color: #ffd04d;
}
.pfc-notification-bar_positive {
    background-color: #afe7b4;
}
.pfc-notification-bar_neutral {
    background-color: #e2e2e2;
}
.pfc-notification-bar .detail-btn {
    border: 1px #ffffff solid;
    box-shadow: none;
    margin-left: 30px;
    background: #ffffff;
}
.pfc-notification-bar .close-notification {
    background: none;
    border: none;
    position: absolute;
    right: 57px;
    font-size: 16px;
    padding: 0;
}
.pfc-notification-bar .close-notification i {
    padding: 0;
    margin-right: 0;
}
.pfc-notification-bar-content {
    display: flex;
    align-items: center;
    max-width: 1920px;
    padding-left: 157px;
    padding-right: 157px;
}
.pfc-toggle {
    position: relative;
    display: flex;
    align-items: center;
}
.pfc-toggle label {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
    width: 56px;
    height: 30px;
    display: block;
}
.pfc-toggle input {
    position: absolute;
    display: none;
}
.pfc-toggle input:checked ~ .slider {
    background-color: #2bd313;
    border: 1px #2bd313 solid;
}
.pfc-toggle input:checked ~ .slider::before {
    transform: translateX(26px);
    background-color: #ffffff;
    box-shadow: none;
}
.pfc-toggle .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.3s;
    border: 1px #cccccc solid;
}
.pfc-toggle .slider::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #1e3968;
    transition: 0.3s;
}
.pfc-toggle span.label {
    color: #000000;
    margin-left: 15px;
}
.pfc-toggle.label-left {
    flex-direction: row-reverse;
}
.pfc-toggle.label-left span.label {
    margin-left: 0;
    margin-right: 15px;
}
.pfc-toggle.toggle_medium label {
    height: 25px;
    width: 46px;
}
.pfc-toggle.toggle_medium .slider:before {
    height: 19px;
    width: 19px;
}
.pfc-toggle.toggle_medium input:checked ~ .slider::before {
    transform: translateX(21px);
}
.pfc-toggle.toggle_small label {
    height: 20px;
    width: 36px;
}
.pfc-toggle.toggle_small .slider:before {
    height: 14px;
    width: 14px;
}
.pfc-toggle.toggle_small input:checked ~ .slider::before {
    transform: translateX(16px);
}
.pfc-toggle.disabled {
    opacity: 0.5;
    cursor: default;
}
.pfc-toggle.disabled label {
    cursor: default;
}
.pfc-segmented-button {
    padding: 1px;
    background: #ffffff;
    border: 1px #e2e2e2 solid;
    height: 44px;
    border-radius: 22px;
    display: flex;
}
.pfc-segmented-button .btn {
    border: none;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition:
        background 600ms ease,
        color 600ms ease;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    padding: 0 52px;
    font-size: 16px;
    border-radius: 22px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pfc-segmented-button input[type='radio'].toggle {
    display: none;
}
.pfc-segmented-button input[type='radio'].toggle + label {
    cursor: pointer;
}
.pfc-segmented-button input[type='radio'].toggle + label:hover {
    background: none;
}
.pfc-segmented-button input[type='radio'].toggle + label:after {
    background: #e5eaec;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: left 300ms cubic-bezier(0.77, 0, 0.175, 1);
    width: 100%;
    z-index: -1;
    color: #000000;
    border-radius: 22px;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-left + label {
    border-right: 0;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-left + label:after {
    left: 100%;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-right + label {
    margin-left: -5px;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-right + label:after {
    left: -100%;
}
.pfc-segmented-button input[type='radio'].toggle:checked + label {
    cursor: default;
    color: #000000;
    transition: color 200ms;
    z-index: 0;
}
.pfc-segmented-button input[type='radio'].toggle:checked + label:after {
    left: 0;
}
.pfc-segmented-button.disabled {
    opacity: 0.5;
    cursor: default;
}
.pfc-segmented-button.disabled label {
    cursor: default;
}
.pfc-tooltip {
    position: relative;
    display: inline-block;
    font-size: 30px;
}
.pfc-tooltip.tooltip-medium {
    font-size: 24px;
}
.pfc-tooltip.tooltip-medium.tooltip_top .tooltip-content {
    bottom: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_top-left .tooltip-content {
    bottom: 145%;
    right: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_top-right .tooltip-content {
    bottom: 145%;
    left: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom-left .tooltip-content {
    top: 145%;
    right: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom-right .tooltip-content {
    top: 145%;
    left: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom .tooltip-content {
    top: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_right .tooltip-content {
    left: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_left .tooltip-content {
    right: 145%;
}
.pfc-tooltip.tooltip-small {
    font-size: 18px;
}
.pfc-tooltip.tooltip-small.tooltip_top .tooltip-content {
    bottom: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_top-left .tooltip-content {
    bottom: 155%;
    right: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_top-right .tooltip-content {
    bottom: 155%;
    left: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom-left .tooltip-content {
    top: 155%;
    right: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom-right .tooltip-content {
    top: 155%;
    left: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom .tooltip-content {
    top: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_right .tooltip-content {
    left: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_left .tooltip-content {
    right: 155%;
}
.pfc-tooltip:hover {
    overflow: visible;
    opacity: 1 !important;
}
.pfc-tooltip:hover .tooltip-content {
    opacity: 1;
}
.pfc-tooltip .tooltip-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pfc-tooltip .tooltip-content {
    background: #333333;
    box-shadow: 0 5px 5px 5px rgba(205, 210, 214, 0.3);
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
    max-width: 380px;
    min-width: auto;
    width: -webkit-max-content;
    width: max-content;
    padding: 10px;
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease;
    border-radius: 4px;
    line-height: 1.2;
}
.pfc-tooltip .tooltip-content::after {
    background: #333333;
    content: '';
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
}
.pfc-tooltip.tooltip_top .tooltip-content {
    bottom: 145%;
    left: 50%;
    transform: translateX(-50%);
}
.pfc-tooltip.tooltip_top .tooltip-content::after {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_top-left .tooltip-content {
    bottom: 145%;
    right: 0;
}
.pfc-tooltip.tooltip_top-left .tooltip-content::after {
    bottom: -5px;
    right: 10px;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_top-right .tooltip-content {
    bottom: 145%;
    left: 0;
}
.pfc-tooltip.tooltip_top-right .tooltip-content::after {
    bottom: -5px;
    left: 10px;
}
.pfc-tooltip.tooltip_bottom-left .tooltip-content {
    top: 145%;
    right: 0;
}
.pfc-tooltip.tooltip_bottom-left .tooltip-content::after {
    top: -5px;
    right: 10px;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_bottom-right .tooltip-content {
    top: 145%;
    left: 0;
}
.pfc-tooltip.tooltip_bottom-right .tooltip-content::after {
    top: -5px;
    left: 10px;
}
.pfc-tooltip.tooltip_bottom .tooltip-content {
    top: 145%;
    left: 50%;
    transform: translateX(-50%);
}
.pfc-tooltip.tooltip_bottom .tooltip-content::after {
    top: -5px;
    left: 50%;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_right .tooltip-content {
    left: 145%;
    top: 50%;
    transform: translateY(-50%);
}
.pfc-tooltip.tooltip_right .tooltip-content::after {
    left: -5px;
    margin-top: -5px;
    top: 50%;
}
.pfc-tooltip.tooltip_left .tooltip-content {
    right: 145%;
    top: 50%;
    transform: translateY(-50%);
}
.pfc-tooltip.tooltip_left .tooltip-content::after {
    right: -5px;
    margin-top: -5px;
    top: 50%;
}
.pfc-form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.pfc-form-group .input-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e3968;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 18px;
    white-space: nowrap;
}
.pfc-form-group.has-error .input-icon {
    background: #d32f2f;
}
.pfc-form-group.has-error span.error-message {
    display: block;
    margin: 5px 12px;
    width: 100%;
    color: #d32f2f;
    font-size: 12px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-form-group_disabled .input-icon {
    opacity: 0.5;
}
.pfc-form-control {
    position: relative;
    flex: 1 1 auto;
    -webkit-appearance: none;
    appearance: none;
    border: 1px #e2e2e2 solid;
    border-radius: 0;
    background: #ffffff;
    background-clip: padding-box;
    padding: 12px;
    width: 1%;
    min-width: 0;
    height: 40px;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-form-control:-ms-input-placeholder {
    color: #cccccc;
}
.pfc-form-control::placeholder {
    color: #cccccc;
}
.pfc-form-control:active,
.pfc-form-control:focus-visible {
    outline: none;
    border-color: #1e3968;
    border-radius: 0;
    color: #000000;
}
.pfc-form-control:disabled {
    opacity: 50%;
}
.pfc-form-control:disabled:active {
    border: 1px #e2e2e2 solid;
}
.pfc-form-control--error,
.pfc-form-control:invalid {
    border-color: #d32f2f;
    color: #000000;
}
.pfc-form-control--error + span.error-message,
.pfc-form-control:invalid + span.error-message {
    display: block;
    margin: 5px 12px;
    width: 100%;
    color: #d32f2f;
    font-size: 12px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
textarea {
    resize: none;
}
textarea.pfc-form-control {
    padding: 10px 12px;
    min-height: 104px;
}
.pfc-select {
    display: flex;
    position: relative;
    flex-basis: 100%;
    border: 1px #e2e2e2 solid;
    background: #fff;
    padding: 0;
    height: 40px;
    font-size: 16px;
}
.pfc-select.disabled {
    opacity: 0.5;
}
.pfc-select.disabled .inputContainer input {
    cursor: default;
}
.pfc-select.error {
    border-color: #d32f2f;
}
.pfc-select .inputContainer {
    width: 100%;
}
.pfc-select .inputContainer input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 12px;
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-select .inputContainer input:focus-visible {
    outline: none;
    border: none;
}
.pfc-select .inputContainer:after {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 0;
    cursor: pointer;
    content: '';
    content: '\64';
    color: #1e3968;
    font-size: 14px;
    font-family: 'pfc-font-icon';
}
.pfc-select .inputContainer.select-active:after {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(180deg);
    transition: 0.25s ease-in-out;
    content: '\64';
    font-family: 'pfc-font-icon';
}
.pfc-select ul.dropdown-list_all {
    display: none;
    position: absolute;
    top: 39px;
    right: 0;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    border: 1px #e2e2e2 solid;
    border-top: none;
    background: #ffffff;
    padding: 0;
    width: 100%;
    list-style: none;
}
.pfc-select ul.dropdown-list_all li {
    cursor: pointer;
    margin-bottom: 0;
    padding: 8px 12px;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-select.pfc-select_color .inputContainer span.color-icon {
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 24px;
    height: 24px;
}
.pfc-select.pfc-select_color .inputContainer input {
    padding-left: 42px;
}
.pfc-select.pfc-select_color ul.dropdown-list_all {
    grid-gap: 5px;
    gap: 5px;
    padding: 10px;
    flex-direction: row;
}
.pfc-select.pfc-select_color ul.dropdown-list_all li {
    display: inline-block;
    padding: 0;
}
.pfc-select.pfc-select_color ul.dropdown-list_all li span.logo-color_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
}
.pfc-select.pfc-select_color ul#dropdown-list_all_pfc-select_color {
    flex-direction: row;
}
.pfc-select.pfc-select_image .inputContainer img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 38px;
    height: 38px;
}
.pfc-select.pfc-select_image .inputContainer input {
    padding-left: 50px;
}
.pfc-select.pfc-select_image ul {
    padding: 8px;
    grid-gap: 10px;
    gap: 10px;
}
.pfc-select.pfc-select_image ul li {
    display: flex;
    align-items: center;
    padding: 0;
}
.pfc-select.pfc-select_image ul li:last-child {
    margin-bottom: 0;
}
.pfc-select.pfc-select_image ul li img {
    display: inline-block;
    margin-right: 10px;
    width: 38px;
    height: 38px;
}
.pfc-dialog {
    max-width: 1920px;
    width: 100%;
    padding: 0 57px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pfc-dialog .modal-inner-wrap {
    position: relative;
    background: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 22px;
    box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-dialog .modal-inner-wrap [data-role='header'],
.pfc-dialog .modal-inner-wrap header {
    height: auto;
    padding-top: 0;
    position: absolute;
    right: 0;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close,
.pfc-dialog .modal-inner-wrap header .action-close {
    background: 0 0;
    border: 0;
    padding: 0 20px;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close:before,
.pfc-dialog .modal-inner-wrap header .action-close:before {
    font-family: 'pfc-font-icon';
    font-size: 20px;
    content: '\6b';
    color: #000000;
    padding: 0;
    margin: 0;
    vertical-align: unset;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close span,
.pfc-dialog .modal-inner-wrap header .action-close span {
    display: none;
}
.pfc-dialog .modal-inner-wrap .modal-content-wrap [data-role='content'] {
    padding-left: 10px;
    padding-right: 10px;
}
.pfc-dialog .modal-inner-wrap .modal-content-wrap [data-role='content'] h2 {
    margin-top: 0;
    margin-bottom: 0;
}
.pfc-dialog .modal-inner-wrap .modal-content-inner {
    margin-top: 20px;
}
.pfc-dialog .modal-inner-wrap .modal-content-inner p {
    margin: 0;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer button.action {
    margin-left: 20px;
    margin-right: 0;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer button.action:first-child {
    margin-left: 0;
}
.pfc-dialog_small .modal-inner-wrap {
    min-width: unset;
    width: 33.33% !important;
}
.pfc-dialog_medium .modal-inner-wrap {
    min-width: unset;
    width: 50% !important;
}
.pfc-dialog_large .modal-inner-wrap {
    min-width: unset;
    width: 66.66% !important;
}
.pfc-dialog_max .modal-inner-wrap {
    min-width: unset;
    width: 83.33% !important;
}
.pfc-dialog_top {
    align-items: flex-start;
    padding-top: 40px;
}
.pfc-dialog_middle {
    align-items: center;
}
.pfc-dialog_bottom {
    align-items: flex-end;
    padding-bottom: 40px;
}
.container {
    max-width: 1920px;
    padding-left: 57px;
    padding-right: 57px;
}
.flex-row {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.flex-col {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
}
.flex-col-xs-1,
.flex-col-sm-1,
.flex-col-md-1,
.flex-col-lg-1,
.flex-col-xl-1,
.flex-col-xs-2,
.flex-col-sm-2,
.flex-col-md-2,
.flex-col-lg-2,
.flex-col-xl-2,
.flex-col-xs-3,
.flex-col-sm-3,
.flex-col-md-3,
.flex-col-lg-3,
.flex-col-xl-3,
.flex-col-xs-4,
.flex-col-sm-4,
.flex-col-md-4,
.flex-col-lg-4,
.flex-col-xl-4,
.flex-col-xs-5,
.flex-col-sm-5,
.flex-col-md-5,
.flex-col-lg-5,
.flex-col-xl-5,
.flex-col-xs-6,
.flex-col-sm-6,
.flex-col-md-6,
.flex-col-lg-6,
.flex-col-xl-6,
.flex-col-xs-7,
.flex-col-sm-7,
.flex-col-md-7,
.flex-col-lg-7,
.flex-col-xl-7,
.flex-col-xs-8,
.flex-col-sm-8,
.flex-col-md-8,
.flex-col-lg-8,
.flex-col-xl-8,
.flex-col-xs-9,
.flex-col-sm-9,
.flex-col-md-9,
.flex-col-lg-9,
.flex-col-xl-9,
.flex-col-xs-10,
.flex-col-sm-10,
.flex-col-md-10,
.flex-col-lg-10,
.flex-col-xl-10,
.flex-col-xs-11,
.flex-col-sm-11,
.flex-col-md-11,
.flex-col-lg-11,
.flex-col-xl-11,
.flex-col-xs-12,
.flex-col-sm-12,
.flex-col-md-12,
.flex-col-lg-12,
.flex-col-xl-12,
.flex-col-auto {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.flex-col-seperator {
    width: 100%;
}
.flex-col-xs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}
.flex-col-xs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}
.flex-col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
}
.flex-col-xs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}
.flex-col-xs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}
.flex-col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
}
.flex-col-xs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}
.flex-col-xs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}
.flex-col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
}
.flex-col-xs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}
.flex-col-xs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}
.flex-col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
}
.flex-col-xs-offset-1 {
    margin-left: 8.33333333%;
}
.flex-col-xs-offset-2 {
    margin-left: 16.66666667%;
}
.flex-col-xs-offset-3 {
    margin-left: 25%;
}
.flex-col-xs-offset-4 {
    margin-left: 33.33333333%;
}
.flex-col-xs-offset-5 {
    margin-left: 41.66666667%;
}
.flex-col-xs-offset-6 {
    margin-left: 50%;
}
.flex-col-xs-offset-7 {
    margin-left: 58.33333333%;
}
.flex-col-xs-offset-8 {
    margin-left: 66.66666667%;
}
.flex-col-xs-offset-9 {
    margin-left: 75%;
}
.flex-col-xs-offset-10 {
    margin-left: 83.33333333%;
}
.flex-col-xs-offset-11 {
    margin-left: 91.66666667%;
}
.flex-col-xs-offset-12 {
    margin-left: 100%;
}
.flex-col-auto {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
@media only screen and (min-width: 576px) {
    .flex-col {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-sm-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-sm-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-sm-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-sm-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-sm-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-sm-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-sm-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-sm-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-sm-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-sm-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-sm-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-sm-offset-3 {
        margin-left: 25%;
    }
    .flex-col-sm-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-sm-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-sm-offset-6 {
        margin-left: 50%;
    }
    .flex-col-sm-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-sm-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-sm-offset-9 {
        margin-left: 75%;
    }
    .flex-col-sm-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-sm-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-sm-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 768px) {
    .flex-col {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-md-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-md-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-md-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-md-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-md-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-md-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-md-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-md-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-md-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-md-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-md-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-md-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-md-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-md-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-md-offset-3 {
        margin-left: 25%;
    }
    .flex-col-md-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-md-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-md-offset-6 {
        margin-left: 50%;
    }
    .flex-col-md-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-md-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-md-offset-9 {
        margin-left: 75%;
    }
    .flex-col-md-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-md-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-md-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 992px) {
    .flex-col {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-lg-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-lg-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-lg-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-lg-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-lg-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-lg-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-lg-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-lg-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-lg-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-lg-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-lg-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-lg-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-lg-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-lg-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-lg-offset-3 {
        margin-left: 25%;
    }
    .flex-col-lg-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-lg-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-lg-offset-6 {
        margin-left: 50%;
    }
    .flex-col-lg-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-lg-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-lg-offset-9 {
        margin-left: 75%;
    }
    .flex-col-lg-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-lg-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-lg-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 1200px) {
    .flex-col {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-xl-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-xl-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-xl-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-xl-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-xl-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-xl-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-xl-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-xl-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-xl-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-xl-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-xl-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-xl-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-xl-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-xl-offset-3 {
        margin-left: 25%;
    }
    .flex-col-xl-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-xl-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-xl-offset-6 {
        margin-left: 50%;
    }
    .flex-col-xl-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-xl-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-xl-offset-9 {
        margin-left: 75%;
    }
    .flex-col-xl-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-xl-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-xl-offset-12 {
        margin-left: 100%;
    }
}
.show {
    display: block !important;
}
.hide {
    display: none !important;
}
.show-xs {
    display: block !important;
}
.hide-xs {
    display: none !important;
}
@media only screen and (max-width: 575px) {
    .show-xs-only {
        display: block !important;
    }
    .hide-xs-only {
        display: none !important;
    }
}
@media only screen and (min-width: 576px) {
    .show-sm {
        display: block !important;
    }
    .hide-sm {
        display: none !important;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .show-sm-only {
        display: block !important;
    }
    .hide-sm-only {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) {
    .show-md {
        display: block !important;
    }
    .hide-md {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .show-md-only {
        display: block !important;
    }
    .hide-md-only {
        display: none !important;
    }
}
@media only screen and (min-width: 992px) {
    .show-lg {
        display: block !important;
    }
    .hide-lg {
        display: none !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .show-lg-only {
        display: block !important;
    }
    .hide-lg-only {
        display: none !important;
    }
}
@media only screen and (min-width: 1200px) {
    .show-xl {
        display: block !important;
    }
    .hide-xl {
        display: none !important;
    }
}
.pfc-accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pfc-accordion-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 23px;
    border-top: 1px #e2e2e2 solid;
    border-left: 3px transparent solid;
    transition: all 0.5s linear;
}
.pfc-accordion-trigger:hover {
    cursor: pointer;
}
.pfc-accordion-trigger:first-child {
    border-top: none;
}
.pfc-accordion-trigger.open {
    border-bottom: none;
    border-left: 3px #3082f9 solid;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.5s ease-out;
}
.pfc-accordion-trigger.open i {
    background: #3082f9;
    rotate: 90deg;
    color: #ffffff;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}
.pfc-accordion-trigger h4 {
    margin-top: 0;
    margin-bottom: 0;
}
.pfc-accordion-trigger i {
    font-size: 14px;
    color: #778186;
    background: #f1f4f5;
    padding: 10px;
    border-radius: 100px;
    margin-right: 15px;
    transition: all 0.3s ease-out;
}
.pfc-accordion-content {
    padding: 30px 40px 40px;
    border-left: 3px transparent solid;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.pfc-accordion-content.expanded {
    border-bottom: none;
    opacity: 1;
    border-left: 3px #3082f9 solid;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
}
.pfc-tab .mage-tabs-disabled,
.pfc-tab .mage-tabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.pfc-tab .item.title {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
.pfc-tab .item.title a {
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 20px;
    line-height: 1.2;
    padding: 0 20px;
    height: 78px;
    background: #f6f6f6;
    border: 1px #e2e2e2 solid;
    border-left: none;
    color: #000000;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.pfc-tab .item.title a i {
    font-size: 37px;
    margin-left: 20px;
}
.pfc-tab .item.title a .tab-title span {
    display: block;
}
.pfc-tab .item.title a .tab-title span.tab-label_secondary {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-tab .item.title.active a {
    background: #ffffff;
}
.pfc-tab .item.title.active a::after {
    position: absolute;
    bottom: 0;
    height: 5px;
    background: #f59d08;
    width: 100%;
    right: 0;
    content: '';
}
.pfc-tab .item.title:first-child a {
    border-left: 1px #e2e2e2 solid !important;
}
.pfc-tab_medium .item.title a {
    font-size: 18px;
    padding: 0 20px;
    height: 68px;
}
.pfc-tab_medium .item.title a i {
    font-size: 32px;
    margin-left: 18px;
}
.pfc-tab_small .item.title a {
    font-size: 16px;
    padding: 0 20px;
    height: 58px;
}
.pfc-tab_small .item.title a i {
    font-size: 26px;
    margin-left: 16px;
}
.pfc-icon,
a.pfc-icon,
span.pfc-icon,
button.pfc-icon {
    display: flex;
    align-items: center;
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 15.988px;
    font-weight: normal;
    height: 36px;
    width: 36px;
    font-family: 'pfc-font-icon';
    font-size: 20px;
    content: '';
    color: #000;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    position: relative;
}
.pfc-icon i,
a.pfc-icon i,
span.pfc-icon i,
button.pfc-icon i {
    color: #000;
    font-size: 20px;
    z-index: 2;
}
.pfc-icon::before,
a.pfc-icon::before,
span.pfc-icon::before,
button.pfc-icon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: #f0f4f6;
    border-radius: 24px;
    z-index: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0);
}
.pfc-icon:hover::before,
a.pfc-icon:hover::before,
span.pfc-icon:hover::before,
button.pfc-icon:hover::before {
    transform: scale(1);
    opacity: 1;
}
.pfc-icon:after,
a.pfc-icon:after,
span.pfc-icon:after,
button.pfc-icon:after {
    font-weight: normal;
    font-family: 'pfc-font-icon';
    font-size: 24px;
    content: '\3a';
    color: #778186;
    padding: 0 0px 0 8px;
    margin: 0;
    vertical-align: bottom;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 69px;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
}
.pfc-icon:hover,
a.pfc-icon:hover,
span.pfc-icon:hover,
button.pfc-icon:hover {
    text-decoration: none;
}

.bg-white {
    background: white;
}
.bg-white input:checked ~ .slider::before {
    text-indent: -9999px;
}
/* .line div > div {
    outline: 1px solid red;
} */
.line + .line {
    margin-bottom: 0 !important;
}
.cc-tborder {
    border-radius: 8px;
    border: 1px solid #e2e2e2;

    padding: 10px 20px 20px;
    height: 116px;
    position: relative;
}
.cc-tborder.m-b20 {
    height: auto;
}

.cc-label {
    height: 36px;
}
.main-colour {
    width: 74px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.accent-colour {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.vline {
    border: 1px solid #999999;
    margin: 10px 30px 0;
    padding-top: 10px;
    width: 1px;
    height: 73px;
    display: block;
}

.cc-tlist {
    background: #f6f6f6;
}
.cc-tlist .relative button:hover {
    background: none;
    border: 0;
}
.fz14.fw-325.lh21.gotham-book {
    margin-bottom: 0;
}
.cc-tutitle {
    height: 36px;
}
.cc-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    display: block;
}
.layer {
    height: 116px;
}
.layer.m-b20 {
    height: auto;
}
.layer:after {
    content: ' ';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(233, 233, 233, 0.4);
    /* padding: 10px 20px 20px; */
    position: absolute;
    left: 0px;
    top: 0;
    border-radius: 8px;
}
.up-icon {
    width: 56px;
    height: 56px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 100%;
}

.cc-dialog {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    background: rgba(233, 233, 233, 0.7);
    max-width: 100% !important;
}
.cc-dialog h2 {
    height: 35px;
}
.cc-dialog.color-picker > .modal-inner-wrap {
    min-width: 615px;
    max-width: 615px;
    min-height: 405px;
    max-height: 405px;
}

.color-box {
    width: 60px;
    height: 60px;
    border: 1px solid black;
    position: absolute;
    left: 302px;
}
.fix_cmyk {
    /* display: none; */
    position: absolute;
    left: 392px;
    /* top: 5px; */
    /* outline: 1px solid green; */
}
.fix_cmyk span {
    display: block;
    /* position: absolute; */
    /* position: relative; */
    text-transform: uppercase;
    left: -69px;
    top: 0px;
    font-size: 16px;
    line-height: 16px;
    width: 10px !important;
    height: 40px !important;
    float: left;
    padding: 12px 0 0 10px;
    /* outline: 1px solid red; */
}
.fix_cmyk input {
    width: 49px !important;
    height: 40px !important;
    padding-left: 9px !important;
    padding-top: 3px !important;
    font-size: 16px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div label {
    top: 12px !important;
    font-size: 16px !important;
    line-height: 16px !important;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) {
    /* top: -122px; */
    position: absolute;
    /* top: -105px; */
    top: -90px;
    left: 87px;
    /* right: -85px; */
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div input {
    height: 40px !important;
    width: 49px !important;
    font-size: 16px !important;
    line-height: 16px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(5) {
    position: absolute;
    top: -145px;
    left: 170px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(6) {
    top: -145px;
    left: 170px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(7) {
    top: -145px;
    left: 170px;
}
/* .pfc-toggle .slider,
.pfc-toggle input:checked ~ .slider {
    width: 56px;
} */
.pfc-toggle input:checked ~ .slider:before {
    border: 0;
}

/* FLEX */
.flex {
    display: flex;
}
.flex-shink-0 {
    flex-shrink: 0;
}
.flex-inline {
    display: inline-flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.justify-space-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: end;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex1 {
    flex: 1 0 50%;
}
.flex01 {
    flex: 1 1;
}
.flex01 svn {
    display: block;
}

/* HEIGHT */
.h-f,
.h-all {
    height: 100%;
}
.h-full {
    height: 522px;
}
.h-auto {
    height: auto;
}
.h-50 {
    height: 50px;
}
.h-525 {
    height: 525px;
}
.h-44 {
    height: 44px;
}

/* WIDTH */
.wa {
    width: auto;
}
.w-full {
    width: 100% !important;
}
.w-80 {
    width: 80%;
}
.w40 {
    width: 40px;
}
.w244 {
    width: 244px;
}
.w-half {
    width: 50%;
}

/* FONT FAMILY */
.gotham-black {
    font-family: "Gotham-Black", Verdana, Arial, sans-serif;
}
.gotham-bold {
    font-family: "Conv_Gotham-Bold", Verdana, Arial, sans-serif;
}
.gotham-medium {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}
.gotham-book {
    font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif;
}

/* FRON DYSON LESS */

.font-gotham_light {
    font-family: "Gotham-Light", Verdana, Arial, sans-serif;
    font-weight: 300;
    /*   // font-weight: 300 */
}
.font-gotham_book {
    font-family: "Gotham-Book", Verdana, Arial, sans-serif;
    font-weight: 325;
    /*   // font-weight: 325 */
}
.font-gotham_medium {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
    font-weight: 350;
    /*   // font-weight: 350 */
}
.font-gotham_bold {
    font-family: "Gotham-Bold", Verdana, Arial, sans-serif;
    font-weight: 400;
    /*   // font-weight: 400 */
}
.font-gotham_black {
    font-family: "Gotham-Black", Verdana, Arial, sans-serif;
    font-weight: 450;
    /*   // font-weight: 450 */
}
.font-gotham_ultra {
    font-family: "Gotham-Ultra", Verdana, Arial, sans-serif;
}

/* NOTE: ADMIN SETTING */
.inner-b-fmedium b {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}

/* form element fix */
.text-bold_small input[type="text"] {
    font-family: inherit !important;
}
.text-semi-bold_medium input[type="text"] {
    font-family: inherit !important;
}
.text-semi-bold_small input[type="text"] {
    font-family: inherit !important;
    font-size: inherit;
}
.gotham-black input[type="text"] {
    font-family: inherit !important;
}

/* FONT WEIGHT */
.fw-normal {
    font-weight: normal;
}
.fw-325 {
    font-weight: 325 !important;
}
.fw-350 {
    font-weight: 350;
}
.fw-400 {
    font-weight: 400;
}
.fw-450 {
    font-weight: 450;
}

/* FONT SIZE */
.fz39 {
    font-size: 39px !important;
}
.fz19 {
    font-size: 19px !important;
}
.fz16 {
    font-size: 16px !important;
}
.backgroundBox__body .backgroundBox__body p.pz14,
.fz14 {
    font-size: 14px !important;
}
.fz13 {
    font-size: 13.33px !important;
}
.fz12 {
    font-size: 12px !important;
}
.fz11 {
    font-size: 11px !important;
}
.fz10,
.fz_10 {
    font-size: 10px !important;
}
.fz09,
.fz_9 {
    font-size: 9px !important;
}

/* LINE HEIGHT */
.line-height_0 {
    line-height: 0;
}
.line-height_10 {
    line-height: 10px;
}
.line-height_13 {
    line-height: 13px;
}
.lh16,
.line-height_16 {
    line-height: 16px;
}
.line-height_1rem {
    line-height: 1.6rem;
}
.lh21 {
    line-height: 21px;
}
.lh22 {
    line-height: 22.8px;
}
.lh24 {
    line-height: 24px;
}
.lh46 {
    line-height: 46.8px;
}
/* TEXT */
.text-uppercase {
    text-transform: uppercase;
}
.textalign-right {
    text-align: right;
}
.textalign-center {
    text-align: center;
}
.text-lowercase-first {
    text-transform: lowercase;
}
.text-lowercase-first::first-letter {
    text-transform: uppercase;
}

/* Text Wrap */
.break-word {
    word-wrap: break-word;
}

/* MARGIN */
#presentation_container .mt20 {
    margin-top: 20px;
}
.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px;
}

.mr5 {
    margin-right: 5px;
}
.mr5 {
    margin-right: 5px;
}
.mr8 {
    margin-right: 8px;
}
.mr10 {
    margin-right: 10px;
}
.mr12 {
    margin-right: 12px;
}
.mr14 {
    margin-right: 14px;
}
.mr15 {
    margin-right: 15px;
}
.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}
.mb60 {
    margin-bottom: 60px;
}
.mb30 {
    margin-bottom: 30px;
}
.mb20 {
    margin-bottom: 20px;
}
#presentation_container .mb15,
.mb15 {
    margin-bottom: 15px;
}
.mb8 {
    margin-bottom: 8px;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb12 {
    margin-bottom: 12px;
}
.m-b_60 {
    margin-bottom: 60px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb0 {
    margin-bottom: 0;
}
.ml5 {
    margin-left: 5px;
}
.ml10 {
    margin-left: 10px;
}
.my20 {
    margin: 20px 0;
}

/* PADDING */
.pt-10 {
    padding-top: 10px;
}
.pt15 {
    padding-top: 15px;
}
.pt-20 {
    padding-top: 20px;
}
.pt5 {
    padding-top: 5px;
}
.pr20 {
    padding-right: 20px;
}
.backgroundBox__body p.py20,
.py20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py10,
.py {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.py20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.px25 {
    padding-left: 25px;
    padding-right: 25px;
}
.px30 {
    padding-left: 30px;
    padding-right: 30px;
}
.px20 {
    padding-left: 20px;
    padding-right: 20px;
}
.px {
    padding-left: 15px;
    padding-right: 15px;
}
.p06 {
    padding: 6px;
}
.p20 {
    padding: 20px;
}
.p-t_20 {
    padding-top: 20px;
}
.pt110 {
    padding-top: 110px;
}
.pr0 {
    padding-right: 0;
}
.pr10 {
    padding-right: 10px;
}
.pr14 {
    padding-right: 14px;
}
.p-r_20 {
    padding-right: 20px;
}
.pb10 {
    padding-bottom: 10px;
}
.pb20 {
    padding-bottom: 20px;
}
.pl10 {
    padding-left: 10px;
}
.pl30 {
    padding-left: 30px;
}
.p5 {
    padding: 5px;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#pf-ptool {
    height: 100%;
    min-width: 460px;
    padding-right: 0;
    padding-left: 0;
}
html {
    -webkit-print-color-adjust: exact;
    /* -webkit-filter: opacity(1); */
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #d8e0e5; */
    /* background: #f1f1f1; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* header {
  background: blue;
  min-height: 70px;
  color: #fff;
} */

.drawContainer {
    /* background: #f1f1f1; */
    min-width: 1200px;
    height: 100%;
}

.btn-text {
    display: none;
}

button {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

select:focus {
    outline: none;
    border: 0;
}
select:active,
button:focus,
button:active {
    outline: none;
    border: 0;
    background: none;
}
#ptool-bg,
#presentation_container {
    margin-top: -20px;
    height: 100%;
    /* background-image: url('./assets/background.jpg'); */
    /* background-image: url(assets/bgsvg1.svg); */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADwCAMAAAD2Bf4HAAAAOVBMVEXX4eXX4uve5+7h6vDa5Ozy9vn3+Prt8vbp7vLr8PTl7PHb5uz0+Pro7vLv9Pf4+fzV4On+///X3+LtVQ0dAAAASUlEQVQY02MQZGBgYGQQAJFkQBY8kBsNMhGAzHggFxrkhUMOEEaDnGiQHQPywSErVsiDBNmgkB8I2ZB4ggxCDAzgkGMA04MSAABJ9APv6TTd1wAAAABJRU5ErkJggg==');
    background-repeat: repeat-x;
    /* background-repeat: no-repeat; */
    background-position: top center;
    background-color: #d8e0e5;
    /* background-size: contain; */
}
/* #ptool-bg:before, #presentation_container:before
{
  background-image: url(assets/bgsvg1.svg);
  width:100%;
  content:'';
  height:200px;
} */

#maincontent {
    max-width: 100% !important;
    /* min-height: 100vh !important; */
}

.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.position-right {
    right: 0;
}

/* OVERFLOW */
.overflow-hidden {
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer;
}
.word-break-all {
    word-break: break-all;
}
.word-break-word {
    word-break: break-word;
}

.display-child-block svg {
    display: block;
}
/* .main-page {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px !important;
    padding-left: 57px;
    padding-right: 57px;
    width: 100%;
} */

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#pf-ptool {
    height: 100%;
    min-width: 460px;
}
html {
    -webkit-print-color-adjust: exact;
    /* -webkit-filter: opacity(1); */
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #d8e0e5; */
    /* background: #f1f1f1; */
}

.main {
    transition: transfrom 0.2s ease;
}

#ptool-bg,
#presentation_container {
    height: 100%;
    /* background-image: url('./assets/background.jpg'); */
    /* background-image: url(assets/bgsvg1.svg); */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADwCAMAAAD2Bf4HAAAAOVBMVEXX4eXX4uve5+7h6vDa5Ozy9vn3+Prt8vbp7vLr8PTl7PHb5uz0+Pro7vLv9Pf4+fzV4On+///X3+LtVQ0dAAAASUlEQVQY02MQZGBgYGQQAJFkQBY8kBsNMhGAzHggFxrkhUMOEEaDnGiQHQPywSErVsiDBNmgkB8I2ZB4ggxCDAzgkGMA04MSAABJ9APv6TTd1wAAAABJRU5ErkJggg==');
    background-repeat: repeat-x;
    /* background-repeat: no-repeat; */
    background-position: top center;
    background-color: #d8e0e5;
    /* background-size: contain; */
}
#presentation_container button:hover {
    border: 0;
}
.cc-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 997;
}
/**
* button remove border and background
* 1. Title bar close button
* */
body .btn-clean {
    background: none;
    border: 0;
    cursor: pointer;
}
.btn-clean:hover {
    border: 0;
}
.hide {
    display: none;
}

.cur-pointer {
    cursor: pointer;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.fixed {
    position: fixed;
}

.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}

button {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

select:focus {
    outline: none;
    border: 0;
}
select:active,
button:focus,
button:active {
    outline: none;
    border: 0;
    background: none;
}

/* OVER CSS */
#app_wrapper input[type='checkbox']:checked + span:before {
    content: ' ';
    border: 0;
}

body.usercollection-presentation-index .page-footer .footer,
.quotation-presentation-index .page-footer .footer {
    margin-top: 0;
}

body.usercollection-presentation-overview .page-footer .footer {
    margin-top: 0;
}

.quotation-presentation-index .page-main {
    padding-left: 0;
    padding-right: 0;
}

#presentation_container {
    position: relative;
    padding-bottom: 40px;
    /* min-height: 100vh !important; */
}

#presentation_container h3 {
    margin-top: 0;
}

/* #presentation_container .setting__wrap .setting__list .setting__element input[type=checkbox] 
{

    opacity: 1 !important;
    position: relative;
    z-index: 1;

} */

#pt-title h2 {
    font-family: 'Conv_Gotham-Black', Verdana, Arial, sans-serif;
    font-weight: 400;
    margin: 2px 0 0;
}

.navigation {
    z-index: 2;
}
.pf-full-backdrop {
    z-index: 1;
}
.setting__wrap {
    z-index: 4;
}
.setting_inside_wrap {
    z-index: 6;
}
.setting__overlay {
    z-index: 5;
}

#presentation_container button:active,
#presentation_container button:focus #presentation_container .mailsharevalue button:active,
#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover {
    background: transparent;
    border: none;
    color: inherit;
}
/* #presentation_container button.strip__add__btn:active, 
#presentation_container button.strip__add__btn:focus
{
    border: 1px solid #ccc ;
    color: #000;
} */
/* #pt-title button:active, #pt-title button:focus 
{
    background: none ;
    border:none ;
} */

#presentation_container button.editbackgroundimage:active,
#presentation_container button.editbackgroundimage:focus {
    background: hsla(0, 0%, 100%, 0.6) !important;
}
.titletext p {
    /* line-height: 1 !important; */
    line-height: 0.9 !important;
    margin-bottom: 0 !important;
}
.titletext p span + span {
    margin-top: 3px;
    display: block;
}
.titletext p:nth-child(2) {
    padding-top: 4px;
}
/* #presentation_container .ecqEditbutton button:active, #presentation_container .ecqEditbutton button:focus,  #presentation_container .ecqEditbutton button:hover
{
    background: #fff;
} */

.editablebox_input[type='number'],
.editablebox_input[type='text'] {
    width: 64px;
    background: transparent;
    border: none;
    padding: 0 0 0 10px;
    height: 30px;
    font-size: 12px;
    box-shadow: none;
}

.EditableItemCell input[type='text']:disabled {
    opacity: 1;
}

.pf-content-menu li.highlight a:after {
    z-index: 1 !important;
}
.backgroundBox__title h4 {
    margin-top: 10px;
}

.font-gotham-book {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif !important;
}

.font-gotham-medium {
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif !important;
}

.font-gotham-bold {
    font-family: 'Conv_Gotham-Bold', Verdana, Arial, sans-serif !important;
}
.font-gotham-black {
    font-family: 'Conv_Gotham-Black', Verdana, Arial, sans-serif !important;
}

.font-arial-normal {
    font-family: Arial, sans-serif !important;
}

